import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { Modal, Button, Form } from "react-bootstrap";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import SelectComponent from "../../SharedComponents/SelectComponent";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PieChart from "./PieChart";
import BarGraph from "./BarGraph";
import LineChart from "./LineChart";
import {
  getMonthFromRange,
  getShortMonth,
  formatDate,
  getCurrentYear,
  filterTypes,
  getFilterDates,
} from "../../../helperFunctions/utils";
import { thousandAndDecimalFormat } from "../../../utils/common";
import {
  getCustomerAcquisitionCost,
  getMarketingAcquisitionCostDetail,
} from "../../../apis/dashboardReports";
import DashboardTable from "./DashboardTable";

export const costFilterTypes = [
  {
    label: "Cost",
    value: "C",
  },
  {
    label: "Product Cost",
    value: "P_C",
  },
  {
    label: "KOL Sponsorship",
    value: "KOL",
  },
  {
    label: "Partnership with Brands",
    value: "P",
  },
];

const CustomerCostReport = () => {
  const [customerCostResp, setCustomerCostResp] = useState();
  const [sellingChartR1, setSellingChartR1] = useState({
    series: [
      // {
      //   name: "Revenue",
      //   type: "column",
      //   data: [200, 500, 0, 600, 0],
      // },
      {
        name: "Cost",
        type: "column",
        data: [50, 100, 0, 150, 0],
      },
      {
        name: "Cost %",
        type: "line",
        data: [25, 4, 0, 25, 0],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        text: "",
      },
      toolbar: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
        formatter: function (value) {
          return thousandAndDecimalFormat(value, true, 2);
        },
      },
      labels: ["Jan", "Feb", "Mar", "Apr", "May"],
      xaxis: {
        type: "Month",
      },
      yaxis: [
        {
          labels: {
            formatter: function (value) {
              return thousandAndDecimalFormat(value, true, 2);
            },
          },
        },
        {
          opposite: true,
          labels: {
            formatter: function (value) {
              return thousandAndDecimalFormat(value, true, 2);
            },
          },
        },
      ],
      colors: ["#FDBA07B3", "#FF9232B3"],
    },
  });
  const [sellingChartR2, setSellingChartR2] = useState({
    series: [
      // {
      //   name: "Revenue",
      //   type: "column",
      //   data: [200, 500, 0, 600, 0],
      // },
      {
        name: "Cost",
        type: "column",
        data: [50, 100, 0, 150, 0],
      },

      {
        name: "Cost %",
        type: "line",
        data: [25, 4, 0, 25, 0],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        text: "",
      },
      toolbar: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
        formatter: function (value) {
          return thousandAndDecimalFormat(value, true, 2);
        },
      },
      labels: ["Jan", "Feb", "Mar", "Apr", "May"],
      xaxis: {
        type: "Month",
      },
      yaxis: [
        {
          labels: {
            formatter: function (value) {
              return thousandAndDecimalFormat(value, true, 2);
            },
          },
        },
        {
          opposite: true,
          labels: {
            formatter: function (value) {
              return thousandAndDecimalFormat(value, true, 2);
            },
          },
        },
      ],
      colors: ["#FDBA07B3", "#FF9232B3"],
    },
  });

  const [rangeMonth, setRangeMonth] = useState({
    r1: [],
    r2: [],
  });

  const [show, setShow] = useState(false);
  const [detailDataResp, setDetailDataResp] = useState([]);
  const [isLoadingDataResp, setIsLoadingDataResp] = useState(true);
  const [selectedDetailData, setSelectedDetailData] = useState({
    typeName: "",
    type: "",
  });

  const [filterType, setFilterType] = useState("last6Months");

  const currentYear = getCurrentYear();
  const [filterData, setFilterData] = useState({
    date_range: "M",
    ...getFilterDates(filterType),
    type: "C",
  });
  const [inputFilterData, setInputFilterData] = useState({
    date_range: "M",
    ...getFilterDates(filterType),
    type: "R",
  });

  const [selectedCostFilter, setSelectedCostFilter] = useState("C");

  useEffect(() => {
    const firstRange = getMonthFromRange(
      filterData.r1_start_date,
      filterData.r1_end_date
    );
    const secondRange = getMonthFromRange(
      filterData.r2_start_date,
      filterData.r2_end_date
    );

    setRangeMonth({
      r1: firstRange,
      r2: secondRange,
    });
  }, [filterData]);

  const getCustomerAcquisitionCostData = async () => {
    const response = await getCustomerAcquisitionCost(filterData);
    console.log("customer cost report resp", response?.data?.data);
    if (response.status !== "Fail") {
      setCustomerCostResp(response?.data?.data);
    } else {
      toastify(response?.data?.message, "error");
    }
  };
  const getMonthValue = (monthValues, year, month) => {
    const monthValue = monthValues.find(
      (m) => m.year === year && m.month === month
    );
    if (!monthValue) return 0;

    if (!monthValue.count) return 0;

    return monthValue.count;
  };

  useEffect(() => {
    getCustomerAcquisitionCostData();
  }, [filterData]);

  function formatOutput(period) {
    const periodObject = customerCostResp?.find(
      (item) => item.period === period
    );

    if (periodObject) {
      const r1Counts = [];
      const r2Counts = [];

      rangeMonth.r1?.map((monthValue) => {
        r1Counts.push(
          getMonthValue(
            periodObject.r1year,
            monthValue.year,
            monthValue.month + 1
          )
        );
        return false;
      });

      rangeMonth.r2?.map((monthValue) => {
        r2Counts.push(
          getMonthValue(
            periodObject.r2year,
            monthValue.year,
            monthValue.month + 1
          )
        );
        return false;
      });

      return {
        label: period,
        r1year: r1Counts,
        r2year: r2Counts,
      };
    }

    return null; // Return null if the period is not found
  }

  useEffect(() => {
    let costOutput = formatOutput("Cost");

    if (filterData.type === "P_C") {
      costOutput = formatOutput("product cost");
    } else if (filterData.type === "KOL") {
      costOutput = formatOutput("KOL Sponsorship");
    } else if (filterData.type === "P") {
      costOutput = formatOutput("Partnership with Brands");
    }
    // const revenueOutput = formatOutput("product cost");
    const percentageOutput = formatOutput("Customer Acquisition");

    // const highestR1Revenue = revenueOutput?.r1year
    //   ? Math.max(...revenueOutput?.r1year)
    //   : 0;
    const highestR1Cost = costOutput?.r1year
      ? Math.max(...costOutput?.r1year)
      : 0;
    // const highestR2Revenue = revenueOutput?.r2year
    //   ? Math.max(...revenueOutput?.r2year)
    //   : 0;
    const highestR2Cost = costOutput?.r2year
      ? Math.max(...costOutput?.r2year)
      : 0;

    const getOptionYaxis = (highestAxis) => {
      return [
        {
          labels: {
            formatter: function (value) {
              return thousandAndDecimalFormat(value, true, 2);
            },
          },
          max: highestAxis,
        },
        // {
        //   labels: {
        //     formatter: function (value) {
        //       return thousandAndDecimalFormat(value, true, 2);
        //     },
        //   },
        //   max: highestAxis,
        //   show: false,
        // },
        {
          opposite: true,
          labels: {
            formatter: function (value) {
              return thousandAndDecimalFormat(value, true, 2);
            },
          },
        },
      ];
    };
    if (rangeMonth?.r1 && costOutput?.r1year && percentageOutput?.r1year) {
      const labelarray = [];
      rangeMonth.r1?.map((m, index) => {
        const m2 = rangeMonth.r2[index];
        labelarray.push(`${getShortMonth(m.month)} - ${m.year}`);
        labelarray.push(` ${getShortMonth(m2.month)} - ${m2.year}`);
      });

      const color1 = "#1F77B4";
      const color2 = "#21263c";
      const categoriesCount = labelarray.length;
      const colors = Array.from({ length: categoriesCount }, (_, index) =>
        index % 2 === 0 ? color1 : color2
      );

      const r1yearData1 = costOutput?.r1year || [];
      const r2yearData1 = costOutput?.r2year || [];
      const seriesData1 = [];
      const r1yearData2 = percentageOutput?.r1year || [];
      const r2yearData2 = percentageOutput?.r2year || [];
      const seriesData2 = [];

      for (
        let i = 0;
        i < Math.max(r1yearData2.length, r2yearData2.length);
        i++
      ) {
        if (r1yearData2[i] !== undefined) {
          seriesData2.push(r1yearData2[i]);
        }
        if (r2yearData2[i] !== undefined) {
          seriesData2.push(r2yearData2[i]);
        }
      }

      for (
        let i = 0;
        i < Math.max(r1yearData1.length, r2yearData1.length);
        i++
      ) {
        if (r1yearData1[i] !== undefined) {
          seriesData1.push(r1yearData1[i]);
        }
        if (r2yearData1[i] !== undefined) {
          seriesData1.push(r2yearData1[i]);
        }
      }
      setSellingChartR1({
        ...sellingChartR1,
        options: {
          ...sellingChartR1?.options,
          labels: labelarray,
          yaxis: [...getOptionYaxis(highestR1Cost <= 0 ? 100 : highestR1Cost)],
          xaxis: {
            categories: labelarray,
            labels: {
              style: {
                colors: colors,
              },
            },
          },
        },
        series: [
          {
            name: "Cost",
            type: "column",
            data: seriesData1 || [],
          },
          {
            name: "Percentage",
            type: "line",
            data: seriesData2 || [],
          },
        ],
      });
    }
  }, [customerCostResp]);

  const [patientGrowthChart, setPatientGrowthChart] = useState({
    series: [
      {
        name: "Revenue",
        type: "column",
        data: [200, 500, 0, 600, 0],
      },
      {
        name: "Cost",
        type: "column",
        data: [50, 100, 0, 150, 0],
      },

      {
        name: "Cost %",
        type: "line",
        data: [25, 4, 0, 25, 0],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 0, 4],
      },
      title: {
        text: "",
      },
      toolbar: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: ["Jan", "Feb", "Mar", "Apr", "May"],
      xaxis: {
        type: "Month",
      },
      yaxis: [
        {
          title: {
            text: "",
          },
        },
        {
          seriesName: "Cost",
          show: false,
        },
        {
          seriesName: "Percentage",
          opposite: true,
          title: {
            text: "",
          },
        },
      ],
      colors: ["#408BBFB3", "#FDBA07B3", "#FF9232B3"],
    },
  });

  const { r1_start_date, r1_end_date, r2_start_date, r2_end_date } =
    inputFilterData;

  function areRangesEqualMonthCount(
    range1StartDate,
    range1EndDate,
    range2StartDate,
    range2EndDate
  ) {
    // Convert string dates to Date objects
    const r1Start = new Date(range1StartDate);
    const r1End = new Date(range1EndDate);
    const r2Start = new Date(range2StartDate);
    const r2End = new Date(range2EndDate);

    // Calculate the month count for both ranges
    const r1MonthCount =
      (r1End.getFullYear() - r1Start.getFullYear()) * 12 +
      (r1End.getMonth() - r1Start.getMonth());
    const r2MonthCount =
      (r2End.getFullYear() - r2Start.getFullYear()) * 12 +
      (r2End.getMonth() - r2Start.getMonth());

    // Check if both ranges have the same number of months
    return r1MonthCount === r2MonthCount;
  }

  const monthSame = areRangesEqualMonthCount(
    r1_start_date,
    r1_end_date,
    r2_start_date,
    r2_end_date
  );

  const handleApplyFilter = () => {
    if (!monthSame) {
      toastify("Both of range need to be match", "error");
    } else {
      setFilterData({
        ...inputFilterData,
      });
    }
  };

  const getTableDetailFunc = async () => {
    setDetailDataResp([]);
    setIsLoadingDataResp(true);
    const { type, typeName, ...rest } = selectedDetailData;
    const response = await getMarketingAcquisitionCostDetail({
      ...filterData,
      ...rest,
      type: selectedDetailData.type,
    });

    setIsLoadingDataResp(false);
    if (response.status !== "Fail") {
      setDetailDataResp(response?.data?.data);
    } else {
      toastify(response?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (selectedDetailData.type !== "" && selectedDetailData.typeName !== "") {
      getTableDetailFunc();
    }
  }, [selectedDetailData]);

  useEffect(() => {
    setInputFilterData((prevFilterData) => ({
      ...prevFilterData,
      ...getFilterDates(filterType),
    }));
  }, [filterType]);

  // useEffect(() => {
  //   setFilterData((prevFilterData) => ({
  //     ...prevFilterData,
  //     ...getFilterDates(filterType),
  //   }));
  // }, []);
  const [export_PDF, setexportPDF] = useState(false);

  const handleExportPdf = () => {
    setexportPDF(true);

    setTimeout(() => {
      exportPDF();
    }, 1000);
  };
  const exportPDF = () => {
    // have a specific div with an id 'exportArea'
    // that wraps around the elements you want to export.
    const input = document.getElementById("exportArea");
    html2canvas(input, {
      scale: 2,
    }) // scale: 2 to increase the resolution of the screenshot
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.7);

        // Initialize jsPDF
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm", // Set the unit to millimeters
          format: "a4", // Set the page format to A4
        });

        // Calculate the number of pages needed for the screenshot.
        const imgWidth = 180; // A4 size page width in mm
        const pageHeight = 297; // A4 size page height in mm
        const marginTop = 10; // Top margin
        const marginBottom = 10; // Bottom margin
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight + marginTop;

        let position = marginTop;

        // Add image to the first page.
        pdf.addImage(
          imgData,
          "JPEG",
          (pdf.internal.pageSize.width - imgWidth) / 2,
          position,
          imgWidth,
          imgHeight - 10
        );
        heightLeft -= pageHeight;

        // Add new pages if the screenshot spans over multiple pages.
        while (heightLeft >= 0) {
          position = marginTop - pageHeight - marginBottom;
          pdf.addPage();
          pdf.addImage(
            imgData,
            "JPEG",
            (pdf.internal.pageSize.width - imgWidth) / 2,
            position,
            imgWidth,
            imgHeight
          );
          heightLeft -= pageHeight;
        }

        // Save the PDF
        const date = new Date();

        pdf.save(`report_${date}.pdf`);
        setexportPDF(false);
      });
  };
  return (
    <div>
      <div className="d-flex flex-wrap gap-3 w-100 px-4 py-4">
        <div className="drop-down-box col-2 me-2">
          <div className="font-400-20 mb-1">Date Presets:</div>
          <SelectComponent
            options={filterTypes}
            selectedValue={filterType}
            singleSelect
            searchEnabled
            nullDisabled
            onChange={(e) => {
              setFilterType(e.value);
              setInputFilterData({ ...inputFilterData, date_range: e.value });
            }}
          />
        </div>
        <div className="range-1">
          <div className="font-400-20 mb-2">Range 1:</div>
          <div className="date-selector-box">
            <DateInput
              value={inputFilterData.r1_start_date}
              placeholder="Start Date"
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r1_start_date: formattedDate,
                });
              }}
            />
            -
            <DateInput
              placeholder="End Date"
              value={inputFilterData.r1_end_date}
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r1_end_date: formattedDate,
                });
              }}
            />
          </div>
        </div>
        <div className="range-2">
          <div className="font-400-20 mb-2">Range 2:</div>
          <div className="date-selector-box">
            <DateInput
              value={inputFilterData.r2_start_date}
              placeholder="Start Date"
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r2_start_date: formattedDate,
                });
              }}
            />
            -
            <DateInput
              placeholder="End Date"
              value={inputFilterData.r2_end_date}
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r2_end_date: formattedDate,
                });
              }}
            />
          </div>
        </div>
        <Button
          className="addItem align-self-end"
          style={{ height: "47px" }}
          onClick={() => handleApplyFilter()}
        >
          Apply
        </Button>
        <Button
          className="addItem align-self-end"
          style={{ height: "47px" }}
          onClick={() => {
            handleExportPdf();
          }}
        >
          Export
        </Button>
        <div className="col-12">
          <div className="drop-down-box col-2 me-2">
            <div className="font-400-20 mb-1">Cost:</div>
            <SelectComponent
              options={costFilterTypes}
              selectedValue={inputFilterData.type}
              singleSelect
              searchEnabled
              nullDisabled
              onChange={(e) => {
                setSelectedCostFilter(e.label);
                setInputFilterData({ ...inputFilterData, type: e.value });
              }}
            />
          </div>
        </div>
      </div>
      <div className="age-block" id="exportArea">
        <div className="demographic-page-block">
          {/* <h1 className="block-heading">Customer Acquisition Cost</h1> */}
          <div
            className="graphs-block"
            style={{ gridTemplateColumns: "repeat(1, 1fr)" }}
          >
            <div className="graph-item">
              <h2 className="year-title">
                {formatDate(filterData.r1_start_date)} -{" "}
                {formatDate(filterData.r1_end_date)}
                &nbsp;&nbsp;|&nbsp;&nbsp;
                {formatDate(filterData.r2_start_date)} -{" "}
                {formatDate(filterData.r2_end_date)}
              </h2>
              <LineChart chartData={sellingChartR1} />
            </div>
          </div>
        </div>
        <DashboardTable
          name="customerCostResp"
          filterData={filterData}
          rangeMonth={rangeMonth}
          values={customerCostResp}
          firstField="year"
          secondField="year"
          setShow={setShow}
          setSelectedDetailData={setSelectedDetailData}
          export_pdf={export_PDF}
          showPercentage={true}
        />
      </div>
      <Modal
        className=""
        size="lg"
        show={show}
        onHide={() => {
          setShow(false);
        }}
        centered
      >
        <Modal.Header closeButton className="pb-0" style={{ borderWidth: 0 }}>
          <div
            className="d-flex w-100 justify-content-center p-0 alert-heading"
            style={{ fontSize: "1rem" }}
          >
            {selectedDetailData.typeName.charAt(0).toUpperCase() +
              selectedDetailData.typeName.slice(1)}{" "}
            From: {filterData.r1_start_date} - {filterData.r1_end_date}
            &nbsp;&nbsp;To:&nbsp;&nbsp;
            {filterData.r2_start_date} - {filterData.r2_end_date}
          </div>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "scroll", maxHeight: 400 }}>
          <div className="mode-table-block">
            <div className="table-header">
              <div className="table-col">
                <h3 className="col-title">Date</h3>
              </div>
              <div className="table-col">
                <h3 className="col-title">Patient Name</h3>
              </div>
              {/* <div className="table-col">
                <h3 className="col-title">Source/Recommend By</h3>
              </div> */}
              <div className="table-col">
                <h3 className="col-title">Total Amount</h3>
              </div>
              <div className="table-col">
                <h3 className="col-title">Profit</h3>
              </div>
            </div>
            {!isLoadingDataResp ? (
              <>
                {detailDataResp
                  ?.filter((a) => a?.amount !== null)
                  ?.map((item, index) => (
                    <div key={index} className="table-rows">
                      <div className="table-col">
                        <h3 className="col-title">
                          {formatDate(item.created_at)}
                        </h3>
                      </div>
                      <div className="table-col">
                        <h3 className="col-title">{item.fullName || "-"}</h3>
                      </div>
                      {/* <div className="table-col">
                      <h3 className="col-title">{item.recommendFrom || "-"}</h3>
                    </div> */}
                      <div className="table-col">
                        <h3 className="col-title"> {item.amount || "-"}</h3>
                      </div>
                      <div className="table-col">
                        <h3 className="col-title">
                          {parseFloat(item?.profit).toFixed(2) || "-"}
                        </h3>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <span className="d-flex justify-content-center p-2">
                <CircularProgress />
              </span>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomerCostReport;
