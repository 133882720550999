import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PickersDay } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
	Accordion,
	Button,
	Col,
	Form,
	InputGroup,
	Row,
	Stack,
	Table,
	Modal,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { addPhotoComparison, getPatientPhotoComparison, updatePhotoComparison } from "../../../apis/photoComparison";
import { getPatientDetailsById } from "../../../apis/patients";
import {
	addMedicalInfo,
	deleteMedicalInfo,
	getMedicalInfo,
	updateMedicalInfo,
} from "../../../apis/medicalInfo";
import { toastify } from "../../../helperFunctions/toastify";
import { Mentions } from "antd";
import DeleteModal from "../../SharedComponents/DeleteModal";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import '../../../App.css'; 

const StyledPhotoZoom = styled.div`
	div, img {
		width: 100% !important;
	}

	img {
	    max-height: 90vh;
    	object-fit: contain;
	}
`;

export default function TabPhotoComparison({ patientId }) {
	const patientData = useSelector((state) => state.patientData.value);
	const userData = useSelector((state) => state.userData.value);
	const [photoComparison, setPhotoComparison] = useState([]);
	const [selectedBeforePhoto, setSelectedBeforePhoto] = useState();
	const [selectedAfterPhoto, setSelectedAfterPhoto] = useState();
	const [type, setType] = useState();
	const [date, setDate] = useState();
	const [beforeDate, setBeforeDate] = useState();
	const [afterDate, setAfterDate] = useState();
	const [file, setFile] = useState(null);
	const [open, setOpen] = useState(false);
	const [show, setShow] = useState(false);
	const [showPreviewModal, setShowPreviewModal] = useState(false);
	const [showCalendar, setShowCalendar] = useState(false);
	const [showBeforeCalendar, setShowBeforeCalendar] = useState(false);
	const [showAfterCalendar, setShowAfterCalendar] = useState(false);
	const [photoComparisonType, setPhotoComparisonType] = useState(["Before", "After"]);
	const [deleting, setDeleting] = useState("");
	const [updating, setUpdating] = useState("");

	const [zoomBefore, setZoomBefore] = useState(1); // Zoom level for the first Col
	const [zoomAfter, setZoomAfter] = useState(1);   // Zoom level for the second Col
  

	const { Option } = Mentions;

	const addPhoto = async () => {
		if (updating) {
			if (!type || type === "") {
				missingPopup("Type");
			} else if (!date) {
				missingPopup("Date");
			} else {
				const dObj = date ? (typeof date == "string" ? new Date(date) : date) : new Date();
				const formattedDay = `${dObj.getDate().toString().padStart(2, "0")}/${(dObj.getMonth() + 1).toString().padStart(2, "0")}/${dObj.getFullYear()}`;
				let formData = new FormData();
				file && Object.values(file).forEach((val) => {
					formData.append("image", val);
				});
				formData.append("type", type);
				formData.append("date", formattedDay);
				formData.append("patientId", updating);
				formData.append("dataId", updating);
				const response = await updatePhotoComparison(formData);
				if (response?.status !== "Fail") {
					populatePhotoComparison(true);
					setDate(new Date());
					setFile();
					setOpen(false);
					setUpdating();
					toastify("updated!", "success");
				} else {
					toastify(response?.message, "error");
				}
			}
		} else {
			if (!type || type === "") {
				missingPopup("Type");
			} else if (!date) {
				missingPopup("Date");
			} else if (!file || !file.length) {
				missingPopup("File");
			} else {
				const dObj = date ? date : new Date();
				const formattedDay = `${dObj.getDate().toString().padStart(2, "0")}/${(dObj.getMonth() + 1).toString().padStart(2, "0")}/${dObj.getFullYear()}`;
				let formData = new FormData();
				Object.values(file).forEach((val) => {
					formData.append("image", val);
				});
				formData.append("type", type);
				formData.append("date", formattedDay);
				formData.append("patientId", patientData._id);
				const response = await addPhotoComparison(formData);
				if (response?.status !== "Fail") {
					populatePhotoComparison(true);
					setDate(new Date());
					setFile();
					setOpen(false);
					toastify("added Photo Comparison!", "success");
				} else {
					toastify(response?.message, "error");
				}
			}
		}
	}

	const populatePhotoComparison = async (refetchAll) => {
		const response = await getPatientPhotoComparison(patientData?._id, beforeDate?.toLocaleDateString('en-GB'), afterDate?.toLocaleDateString('en-GB'));
		if (response?.status !== "Fail") {
			setPhotoComparison(response);
			let _patient = { ...patientData };
			_patient.photoComparison = response;
			// dispatch(setPatientData(_patient));
		}
	};

	useEffect(() => {
		populatePhotoComparison()
	}, [beforeDate, afterDate])

	useEffect(() => {
		if (patientData?.medicalInfo) {
			populatePhotoComparison();
		}
	}, []);

	const savePatient = async () => {
	};

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const removePhotoComparison = async () => {
		setShow(false);
		const response = await updatePhotoComparison({
			dataId: deleting,
			isDeleted: true,
		});
		if (response?.status !== "Fail") {
			toastify("Photo comparison deleted successfully!", "success");
			populatePhotoComparison(true);
		} else {
			toastify(response?.message, "error");
		}
	};

	const editPhotoComparison = (data) => {
		setOpen(true);
		setType(data.type);
		setDate(data.date);
		setUpdating(data._id);
	};

	const handleWheel = (e, zoomSetter) => {
		// Scroll up (zoom in) if deltaY is negative, down (zoom out) if positive
		console.log('handle wheel run', handleWheel);
		zoomSetter((prevZoom) => {
		  console.log('zoom setter run', zoomSetter);
		  const newZoom = prevZoom + (e.deltaY < 0 ? 0.1 : -0.1); // Change zoom step
		  return Math.max(1, newZoom); // Ensure zoom does not go below 1 (original size)
		});
	  };

	return (
		<div>
			<div className="" style={{ paddingRight: "20px" }}>
				<Row>
					<div variant="pills" className="flex-column mainTab">
						<div className="header px-0">
							<h4>Photo Comparison</h4>
							<button
								className="addBtn"
								onClick={() => {
									if (selectedBeforePhoto && selectedAfterPhoto) {
										setShowPreviewModal(true);
									} else {
										toastify("Please choice before and after photo", "error");
									}
								}}
							>
								Compare Photo
							</button>
						</div>
					</div>
					<Col md={6} style={{ borderRight: "2px solid #333" }}>
						<ClickAwayListener onClickAway={() => setShowBeforeCalendar(false)}>
							<div>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										value={beforeDate}
										onChange={(newValue) => {
											setBeforeDate(newValue?.$d);
											setSelectedBeforePhoto();
											setShowBeforeCalendar(false);
										}}
										open={showBeforeCalendar}
										renderDay={(day, _value, DayComponentProps) => {
											const dObj = day instanceof Date ? day : new Date(day);
											const formattedDay = `${dObj.getDate().toString().padStart(2, "0")}/${(dObj.getMonth() + 1).toString().padStart(2, "0")}/${dObj.getFullYear()}`;
											const allPhotoComparison = photoComparison?.filter((photo) => photo.type === "Before").map((data) => {
												const splitDate = data.date.split("/");
												const date = Number(splitDate[0]);
												const month = Number(splitDate[1]);
												const year = Number(splitDate[2]);
												return `${date <= 9 ? `0${date}` : date}/${month <= 9 ? `0${month}` : month}/${year}`
											});
											const isHighlighted = allPhotoComparison?.includes(formattedDay);
											return (
												<PickersDay
													{...DayComponentProps}
													style={{
														background: (isHighlighted && !DayComponentProps.selected && "bisque"),
														color: _value && !DayComponentProps.selected && "black",
													}}
													className={`${formattedDay}`}
												/>
											);
										}}
										renderInput={({ inputRef, inputProps, InputProps }) => (
											<Form.Group className="mt-3">
												<Form.Label className="headings2">Before Date</Form.Label>
												<InputGroup className="mb-3 d-flex pe-3 align-items-center">
													<Form.Control
														ref={inputRef}
														value={
															beforeDate ? new Date(beforeDate).toLocaleDateString("en-GB", {
																day: "numeric",
																month: "numeric",
																year: "numeric",
															}) : "Select"
														}
														onClick={() => setShowBeforeCalendar(!showBeforeCalendar)}
														readOnly
														placeholder="Select"
													/>
													{beforeDate && (
														<div
															className="icon-width"
															onClick={() => {
																setBeforeDate();
																setSelectedBeforePhoto();
																setShowBeforeCalendar(false);
															}}
														>
															<i className="fa fa-xmark" style={{ fontSize: 24, color: "#0000008A" }}></i>
														</div>
													)}
													<div onClick={() => setShowBeforeCalendar(!showBeforeCalendar)}>
														{InputProps?.endAdornment}
													</div>
												</InputGroup>
											</Form.Group>
										)}
									/>
								</LocalizationProvider>
							</div>
						</ClickAwayListener>
						{photoComparison?.filter((photo) => photo.type === "Before").map((photo, index) => (
							<PhotoComparisonRenderer
								isFirst={index === 0}
								photo={photo}
								handleClose={handleClose}
								show={show}
								setShow={setShow}
								setDeleting={setDeleting}
								editPhotoComparison={editPhotoComparison}
								selectedPhoto={selectedBeforePhoto}
								clickPhotoComparison={(value) => {
									setSelectedBeforePhoto(value);
								}}
							/>
						))}
					</Col>
					<Col md={6} style={{ borderLeft: "2px solid #333" }}>
						<ClickAwayListener onClickAway={() => setShowAfterCalendar(false)}>
							<div>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										value={afterDate}
										onChange={(newValue) => {
											setAfterDate(newValue?.$d);
											setSelectedAfterPhoto();
											setShowAfterCalendar(false);
										}}
										open={showAfterCalendar}
										renderDay={(day, _value, DayComponentProps) => {
											const dObj = day instanceof Date ? day : new Date(day);
											const formattedDay = `${dObj.getDate().toString().padStart(2, "0")}/${(dObj.getMonth() + 1).toString().padStart(2, "0")}/${dObj.getFullYear()}`;
											const allPhotoComparison = photoComparison?.filter((photo) => photo.type === "After").map((data) => {
												const splitDate = data.date.split("/");
												const date = Number(splitDate[0]);
												const month = Number(splitDate[1]);
												const year = Number(splitDate[2]);
												return `${date <= 9 ? `0${date}` : date}/${month <= 9 ? `0${month}` : month}/${year}`
											});
											const isHighlighted = allPhotoComparison?.includes(formattedDay);
											return (
												<PickersDay
													{...DayComponentProps}
													style={{
														background: (isHighlighted && !DayComponentProps.selected && "bisque"),
														color: _value && !DayComponentProps.selected && "black",
													}}
													className={`${formattedDay}`}
												/>
											);
										}}
										renderInput={({ inputRef, inputProps, InputProps }) => (
											<Form.Group className="mt-3">
												<Form.Label className="headings2">After Date</Form.Label>
												<InputGroup className="mb-3 d-flex pe-3 align-items-center">
													<Form.Control
														ref={inputRef}
														value={
															afterDate ? new Date(afterDate).toLocaleDateString("en-GB", {
																day: "numeric",
																month: "numeric",
																year: "numeric",
															}) : "Select"
														}
														onClick={() => setShowAfterCalendar(!showAfterCalendar)}
														readOnly
														placeholder="Select"
													/>
													{afterDate && (
														<div
															className="icon-width"
															onClick={() => {
																setAfterDate();
																setSelectedAfterPhoto();
																setShowAfterCalendar(false);
															}}
														>
															<i className="fa fa-xmark" style={{ fontSize: 24, color: "#0000008A" }}></i>
														</div>
													)}
													<div onClick={() => setShowAfterCalendar(!showAfterCalendar)}>
														{InputProps?.endAdornment}
													</div>
												</InputGroup>
											</Form.Group>
										)}
									/>
								</LocalizationProvider>
							</div>
						</ClickAwayListener>
						{photoComparison?.filter((photo) => photo.type === "After").map((photo, index) => (
							<PhotoComparisonRenderer
								isFirst={index === 0}
								photo={photo}
								handleClose={handleClose}
								show={show}
								setShow={setShow}
								setDeleting={setDeleting}
								editPhotoComparison={editPhotoComparison}
								selectedPhoto={selectedAfterPhoto}
								clickPhotoComparison={(value) => {
									setSelectedAfterPhoto(value);
								}}
							/>
						))}
					</Col>
				</Row>
			</div>

			<div className="">
				<DeleteModal
					content="Confirm Photo Comparison Deletion?"
					onYes={removePhotoComparison}
					onNo={handleClose}
					show={show}
				/>
				<Button
					className="my-add-button my-3"
					onClick={() => {
						setOpen(!open);
						setType();
						setDate();
						setUpdating();
					}}
				>
					+ Add
				</Button>
				{open && (
					<div>
						<div className="d-flex">
							<Form.Group className="col-5 mt-3">
								<Form.Label className="headings2">Type *</Form.Label>
								<select
									className="form-select mb-3"
									aria-label="Default select example"
									onChange={(e) => {
										let val = e.target.value;
										if (val === "") {
											setType();
										} else {
											setType(e.target.value);
										}
									}}
								>
									{!type && <option value={""}>Select</option>}
									{photoComparisonType.map((e) => (
										<option value={e}>{e}</option>
									))}
								</select>
							</Form.Group>
						</div>
						<div className="d-flex">
							<ClickAwayListener onClickAway={() => setShowCalendar(false)}>
								<div>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											value={date}
											onChange={(newValue) => {
												setDate(newValue?.$d);
												setShowCalendar(false);
											}}
											open={showCalendar}
											renderInput={({ inputRef, inputProps, InputProps }) => (
												<Form.Group className="mt-3">
													<Form.Label className="headings2">Date *</Form.Label>
													<InputGroup className="mb-3 d-flex pe-3 align-items-center">
														<Form.Control
															ref={inputRef}
															value={
																date ? new Date(date).toLocaleDateString("en-GB", {
																	day: "numeric",
																	month: "numeric",
																	year: "numeric",
																}) : null
															}
															onClick={() => setShowCalendar(!showCalendar)}
															readOnly
															placeholder="Select"
														/>
														<div onClick={() => setShowCalendar(!showCalendar)}>
															{InputProps?.endAdornment}
														</div>
													</InputGroup>
												</Form.Group>
											)}
										/>
									</LocalizationProvider>
								</div>
							</ClickAwayListener>
						</div>
						<div className="d-flex">
							<Form.Group className="col-5 mt-3">
								<Form.Label className="headings2">Image *</Form.Label>
								<div className="mb-3">
									<input
										type={"file"}
										multiple
										accept={".jpg, .png, .jpeg"}
										onChange={(e) => {
											setFile(e.target.files);
										}}
									/>
								</div>
							</Form.Group>
						</div>
						<Button className="my-save-button mt-3" onClick={addPhoto}>
							Save
						</Button>
					</div>
				)}
			</div>
			<Modal
				// className="mt-5"
				// size="lg"
				dialogClassName="custom-modal-width zoom-modal"
				show={showPreviewModal}
				onHide={() => {
					setShowPreviewModal(false);
				}}
				centered
			>
				<Modal.Header closeButton className="pb-0" style={{ borderWidth: 0 }}>
					<div className="d-flex w-100 justify-content-center p-0 alert-heading">
						Compare Photo
					</div>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col
						md={6}
						style={{
							borderRight: "2px solid #333",
							overflow: "hidden", // Prevent image overflow
						}}
						>
							<StyledPhotoZoom>
								<TransformWrapper>
									<TransformComponent>
										<img src={selectedBeforePhoto} alt="photo1" />
									</TransformComponent>
								</TransformWrapper>
							</StyledPhotoZoom>
						</Col>
						<Col
						md={6}
						style={{
							borderLeft: "2px solid #333",
							overflow: "hidden", // Prevent image overflow
							// position: "relative",
						}}
						onWheel={(e) => handleWheel(e, setZoomAfter)}
						>
							<StyledPhotoZoom>
								<TransformWrapper>
									<TransformComponent>
										<img src={selectedAfterPhoto} alt="photo2" />
									</TransformComponent>
								</TransformWrapper>
							</StyledPhotoZoom>
						{/* <div
							style={{
							width: "100%",
							minHeight: "70vh",
							overflow: "auto", // Allow scrolling if the image overflows
							}}
						>
							<img
							className="cursor-pointer mt-2"
							// style={{
							// 	width: `${zoomAfter * 100}%`, // Apply zoom to width
							// 	height: "auto", // Maintain aspect ratio
							// 	transition: "width 0.2s ease",
							// }}
							style={{
								transform: `scale(${zoomAfter})`, // Use scale for zooming
								transformOrigin: "center center", // Keep zoom centered
								transition: "transform 0.2s ease", // Smooth zoom transition
								width: "100%",
								height: "auto", // Maintain aspect ratio
							  }}
							alt="user"
							key={selectedAfterPhoto}
							src={selectedAfterPhoto}
							/>
						</div> */}
						</Col>
					</Row>
				</Modal.Body>								
			</Modal>
			
		</div>
	);
}

const PhotoComparisonRenderer = (props) => {
	const [previewImage, setPreviewImage] = useState(undefined);
	return (
		<div className="d-flex align-items-center">
			<Accordion defaultActiveKey={props?.isFirst ? ["0"] : undefined} alwaysOpe className="my-3 w-100">
				<Accordion.Item eventKey="0">
					<Accordion.Header className="nurse-accordion-header">
						Photo Comparison
					</Accordion.Header>
					<Accordion.Body
						style={{
							backgroundColor: "#f1f1f1",
						}}
					>
						<div>
							<div className="bold">
								<span>
									{`Photo Comparison (${props?.photo?.date})`}
								</span>
							</div>
							<div className="bold">
								<span className="bold">
									{`Created At ${new Date(props?.photo?.created_at).toLocaleDateString("en-GB")} `}
									{new Date(props?.photo?.created_at).toLocaleTimeString(
										"en-US",
										{
											hour: "numeric",
											minute: "numeric",
											hour12: true,
										}
									)}
								</span>
							</div>
							<div>
								<Stack direction="horizontal" style={{ overflowY: 'auto' }}>
									{props?.photo?.image?.map((image) => {
										return (
											<img
												className="cursor-pointer"
												style={{
													width: "30%",
													aspectRatio: 1 / 1,
													marginRight: "8px",
													border: props?.selectedPhoto === image && "4px solid cyan",
												}}
												alt="user"
												key={image}
												src={image}
												onClick={() => {
													setPreviewImage((img) => img === image ? undefined : image);
													props.clickPhotoComparison(image);
												}}
											/>
										)
									})}
								</Stack>
								{/*
								{previewImage && (
									<img
										className="cursor-pointer mt-2"
										style={{
											width: "100%",
										}}
										alt="user"
										key={previewImage}
										src={previewImage}
									/>
								)}
								*/}
							</div>
						</div>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<div className="d-flex">
				<div
					className="mx-1 cursor-pointer icon-width-2"
					onClick={() => {
						props.setDeleting(props?.photo?._id);
						props.setShow(true);
						}}
					>
						<i className="fa fa-trash"></i>
				</div>
			</div>
		</div>
	)
}
