import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup, Collapse } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

import DateInput from "../../SharedComponents/DateInput";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { getInvoiceId } from "../../../helperFunctions/utils";

import { resetValue } from "../../../redux/slices/StaffFormsSlices";
import { getPaymentMethodList } from "../../../apis/paymentMethod";

import { PaymentWrapper } from "./styled";

const customStyles = {
  cells: {
    style: {
      paddingTop: "4px",
      paddingBottom: "4px"
    },
  },
};

const AddPayment = () => {
	const numberFormatter = Intl.NumberFormat('en-US');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const billing = location.state;
  const patient = billing?.patient;

  const [items, setItems] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [paymentRecord, setPaymentRecord] = useState(billing?.paymentRecord ?? [
    {
      totalAmount: billing?.gstAmount,
      payed: 0,
      remaining: billing?.gstAmount,
      paymentMethod: "",
      currentPaidAmount: 0,
      date: new Date(),
      remarks: ""
    }
  ]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentMethodName, setPaymentMethodName] = useState("");
  const [usePoint, setUsePoint] = useState(0);
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [optionalAmount, setOptionalAmount] = useState(billing?.isPaymentInInstallment);
  const [optionPaymentMethods, setOptionPaymentMethods] = useState([]);
  const [collapseIdx, setCollapseIdx] = useState(0);
  const [setting, setSettings] = useState({});

  useEffect(() => {
    getPaymentMethodList()
    .then((res) => {
      setOptionPaymentMethods(res.result?.data ?? []);
    })

    authorizedRequests({
			url: "membershipSetting/listOfMembershipSetting",
			method: "get",
		})
		.then((res) => {
			setSettings({
				value: res.result?.data[0]?.point,
				conversion: res.result?.data[0]?.conversion,
				memberExpiry: res.result?.data[0]?.expForMember,
				nonMemberExpiry: res.result?.data[0]?.expForNonMember,
				isDouble: res.result?.data[0]?.isDouble,
				rewardPoint: res.result?.data[0]?.rewardPoint,
				rewardAmount: res.result?.data[0]?.rewardAmount,
			});
		})
  }, []);

  useEffect(() => {
    const products = billing?.product?.map((x => (
      {
        type: "Product",
        name: x.name ? x.name : x.product_id?.description,
        price: x.product_unit_price,
        cost: x.product_unit_cost,
        qty: x.product_qty,
        total_price: x.product_price,
        discount: x.product_discount,
        discountType: x.product_discountType,
        promoName: x.promoName,
        promoDiscountType: x.promoDiscountType,
        promoDiscountValue: x.promoDiscountValue
      }
    )));
    const treatments = billing?.treatment?.map((x => (
      {
        type: "Treatment",
        name: x.name ? x.name : x.service_id?.name,
        price: x.service_unit_price,
        qty: x.service_qty,
        total_price: x.service_price,
        discount: x.service_discount,
        discountType: x.service_discountType,
        promoName: x.promoName,
        promoDiscountType: x.promoDiscountType,
        promoDiscountValue: x.promoDiscountValue
      }
    )));
    const packages = billing?.package?.map((x => (
      {
        type: "Package",
        name: x.name ? x.name : x.package_id?.name,
        price: x.package_unit_price,
        qty: x.package_qty,
        total_price: x.package_price,
        discount: x.package_discount,
        discountType: x.package_discountType,
        promoName: x.promoName,
        promoDiscountType: x.promoDiscountType,
        promoDiscountValue: x.promoDiscountValue
      }
    )));

    const others = billing?.others?.map((x => (
      {
        type: "Other",
        name: x.description,
        qty: "-",
        total_price: x.total_price,
        discount: 0,
        promoName: "",
        promoDiscountValue: 0
      }
    )));

    setItems([
      ...packages,
      ...products,
      ...treatments,
      ...others
    ]);
    if (billing?.paymentRecord.length > 0) {
      setPaymentRecord(billing?.paymentRecord);
    } else {
      setPaymentRecord([
        {
          totalAmount: billing?.gstAmount,
          payed: 0,
          remaining: billing?.gstAmount,
          paymentMethod: "",
          currentPaidAmount: 0,
          date: new Date(),
          remarks: ""
        }
      ])
    }
    setRemarks(billing?.remarks ?? "");
    setOptionalAmount(billing?.isPaymentInInstallment ?? false);
    setUsePoint(billing?.usePoints);
  }, [billing]);

  const item_column = [
		{
			name: "Item Description",
      selector: (row) => row.promoName ? `${row.name} (${row.promoName})` : row.name,
			wrap: true,
		},
		{
			name: "Qty",
			selector: (row) => row.qty,
			wrap: true,
    },
    {
      name: "Promo",
			selector: (row) => row.promoDiscountType === "Percentage" ? `${row.promoDiscountValue}%` : `$${row.promoDiscountValue ?? 0}`,
			wrap: true,
    },
    {
			name: "Discount",
			selector: (row) => row.discountType === "Percentage" ? `${row.discount}%` : `$${row.discount}`,
			wrap: true,
		},
		{
			name: "Amount",
			selector: (row) => `$${row.total_price}`,
			wrap: true,
		},
  ];

  const getTotalPaidAmount = () => {
    if (billing?.isPayed === true) {
      return billing?.gstAmount;
    }

    return billing?.paymentRecord[billing.paymentRecord.length - 1]?.payed ?? 0;
  }

  const getRemainingAmount = () => {
    if (billing?.isPayed === true) {
      return 0;
    }

    const amount = paymentRecord[paymentRecord.length - 1]?.remaining ?? billing?.gstAmount;
    return parseFloat((amount).toFixed(2));
  }

  const submitForm = async () => {
		// if (+installmentAmount === 0 && optionalAmount === true) {
		// 	return toastify(
		// 		"Amount you entered cannot be zero",
		// 		"error"
		// 	)
		// }
		// if (+installmentAmount > billing?.gstAmount) {
		// 	return toastify(
		// 		"Amount you entered is greater than remaining amount",
		// 		"error"
		// 	)
    // }
    
    let payload = {};
    if (optionalAmount) {
      const paymentRecord = billing.paymentRecord?.[billing.paymentRecord?.length - 1];
      payload = {
        totalAmount: paymentRecord?.totalAmount ?? billing?.gstAmount,
        payed: (paymentRecord?.payed ?? 0) + (+installmentAmount),
        remaining: ((paymentRecord?.remaining ?? billing?.gstAmount) - (+installmentAmount)).toFixed(2),
        paymentMethod: paymentMethodName,
        currentPaidAmount: +installmentAmount,
      };
    } else {
      payload = {
        totalAmount: billing?.gstAmount,
        payed: billing?.gstAmount,
        remaining: 0,
        paymentMethod: paymentMethodName,
        currentPaidAmount: billing?.gstAmount,
      }; 
    }

    const { result, error } = await authorizedRequests({
      url: "payment/updatePaidBilling",
      method: "post",
      data: {
        dataId: billing.dataId,
        remarks: remarks,
        isPaymentInInstallment: optionalAmount,
        paymentRecord: paymentRecord
        // installmentRecord: payload,
      },
    });

    if (result) {
      toastify("Payment successfully updated", "success");
    }

		dispatch(resetValue("billingForm"));
		navigate("/billing");
		return;
  };
  
  const addPaymentRecord = () => {
    const lastRecord = paymentRecord[paymentRecord.length - 1];
    setPaymentRecord(() => {
      return [
        ...paymentRecord, 
        {
          totalAmount: paymentRecord.length > 0 ? lastRecord.totalAmount : billing?.gstAmount,
          payed: paymentRecord.length > 0 ? lastRecord.payed : 0,
          remaining: paymentRecord.length > 0 ? lastRecord.remaining : billing?.gstAmount,
          paymentMethod: "",
          currentPaidAmount: 0,
          date: new Date(),
          remarks: ""
        }
      ]
    })
  }

  const removePaymentRecord = (index) => {
    setPaymentRecord(() => {
      const updatedRecord = [...paymentRecord];
      updatedRecord.splice(index, 1);

      let accPayed = 0;
      updatedRecord.map((record) => {
        accPayed += Number(record.currentPaidAmount);
        record.payed = accPayed;
        record.remaining = Number(record.totalAmount) - Number(record.payed);
      });

      return updatedRecord;
    });

    if (index !== 0) {
      setCollapseIdx(index - 1);
    }
  }

  const changePaymentRecord = (index, field, value) => {
    setPaymentRecord(() => {
      const updatedRecord = [...paymentRecord];

      if (field === "currentPaidAmount") {
        updatedRecord[index][field] = Number(value);
      } else {
        updatedRecord[index][field] = value;
      }

      let accPayed = 0;
      updatedRecord.map((record) => {
        accPayed += Number(record.currentPaidAmount);
        record.totalAmount = billing?.gstAmount;
        record.payed = accPayed;
        record.remaining = parseFloat((Number(record.totalAmount) - Number(record.payed))?.toFixed(2));
      });

      return updatedRecord;
    })
  }

  return (
    <div className="container">
      <div className="mainHeading px-0">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          ></i>
          <span className="400-28-32">
            {location?.state?.formStatus} List Invoice
          </span>
        </h1>
      </div>
      <PaymentWrapper className="p-3">
        <div className="patient-detail">
          <div>
            <div className="highlighted-container d-flex px-3 p-2 col-10 mb-2">
              <div className="font-500-20 py-1">
                <b>Patient Name</b>
              </div>
              <div className="align-self-center ms-auto font-500-20">
                {patient?.patientProfile?.fullName}
              </div>
            </div>
            <div className="highlighted-container d-flex px-3 p-2 col-10 mb-2">
              <div className="font-500-20 py-1">
                <b>Invoice No</b>
              </div>
              <div className="align-self-center ms-auto font-500-20">
                {getInvoiceId(billing?.invoiceNo)}
              </div>
            </div>
            <div className="highlighted-container d-flex px-3 p-2 col-10 mb-2">
              <div className="font-500-20 py-1">
                <b>Invoice Date</b>
              </div>
              <div className="align-self-center ms-auto font-500-20">
                {billing?.invoiceDate}
              </div>
            </div>
          </div>
        </div>
        <div className="highlighted-container mt-4">
          <div className="item-wrapper">
            <div>
              <div className="p-4" style={{minHeight: "652px", borderRight: "1px solid #ddd"}}>
                <p className="font-500-20">
                  <b>Invoice Items</b>
                </p>

                <DataTable
                  data={items}
                  columns={item_column}
                  customStyles={customStyles}
                />

                <div style={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                  <div style={{width: "400px"}}>
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>Sub Total</b>
                      </div>
                      <div className="align-self-center ms-auto font-500-20">
                        ${numberFormatter.format(billing?.subtotalAmount ?? 0)}
                      </div>
                    </div>
                    {
                      billing?.promo !== 0 && (
                        <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                          <div className="font-500-20 py-1">
                            {
                              billing?.promoType === "Percentage" ? (
                                <b>Discount {`(${billing?.promo ?? 0}%)`}</b>
                              ) : (
                                <b>Discount</b>
                              )
                            }
                          </div>
                          <div className="align-self-center ms-auto font-500-20">
                            {
                              billing?.promoType === "Percentage" ? (
                                `($${numberFormatter.format(((billing?.promo ?? 0) / 100) * (billing?.subtotalAmount ?? 0))})`
                              ) : (
                                `($${numberFormatter.format(billing?.promo ?? 0)})`
                              )
                            
                            }
                          </div>
                        </div>
                      )
                    }

                    {
                      billing?.paymentAdjustments?.map((adjustment, index) => (
                        <div key={index} className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                          <div className="font-500-20 py-1">
                            {
                              adjustment?.discountType === "Percentage" ? (
                                <b>{adjustment.name} {`(${adjustment?.discount ?? 0}%)`}</b>
                              ) : (
                                <b>{adjustment.name}</b>
                              )
                            }
                          </div>
                          <div className="align-self-center ms-auto font-500-20">
                            {
                              adjustment?.discountType === "Percentage" ? (
                                `($${numberFormatter.format(((adjustment?.discount ?? 0) / 100) * (billing?.subtotalAmount ?? 0))})`
                              ) : (
                                `($${numberFormatter.format(adjustment?.discount ?? 0)})`
                              )
                            
                            }
                          </div>
                        </div>
                      ))
                    }

                    {
                      billing?.usePoints !== 0 && (
                        <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                          <div className="font-500-20 py-1">
                            <b>Used Point</b>
                          </div>
                          <div className="align-self-center ms-auto font-500-20">
                            {usePoint} = ${numberFormatter.format(usePoint * ((setting?.conversion || 0) / (setting?.value || 0))) || 0}
                          </div>
                        </div>
                      )
                    }
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>GST</b>
                      </div>
                      <div className="align-self-center ms-auto font-500-20">
                        ${numberFormatter.format((billing?.gstAmount ?? 0) - (billing?.totalAmount ?? 0))}
                      </div>
                    </div>
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>Total GST</b>
                      </div>
                      <div className="align-self-center ms-auto font-500-20">
                        <b>${numberFormatter.format(billing?.gstAmount ?? 0)}</b>
                      </div>
                    </div>

                    <div className="divider"></div>

                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>Total Paid Amount</b>
                      </div>
                      <div className="align-self-center ms-auto font-500-20">
                        <b>${numberFormatter.format(getTotalPaidAmount())}</b>
                      </div>
                    </div>
                    {/* <div style={{ paddingLeft: "25px" }}>
                      {
                        billing?.paymentRecord?.map((paidRecord, index) => (
                          <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2" key={index}>
                            <div className=" font-500-20 py-1" style={{ fontSize: "16px" }}>
                              {
                                paidRecord.date ? (
                                  <span>
                                    Paid Amount ({moment(paidRecord.date).format("DD/MMM/YYYY")})
                                  </span>
                                ) : (
                                  <span>Paid Amount {index + 1}</span>
                                )
                              }
                            </div>
                            <div
                              className="align-self-center ms-auto font-500-20"
                              style={{ fontWeight: "500", fontSize: "16px" }}
                            >
                              ${paidRecord?.currentPaidAmount || 0}
                            </div>
                          </div>
                        ))
                      }
                    </div> */}
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>Remaining Amount</b>
                      </div>
                      <div className="align-self-center ms-auto font-500-20">
                        <b>${numberFormatter.format(getRemainingAmount())}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4">
              {/* <div>
                <Form.Label className="black-label-text" style={{marginRight: "20px"}}>
                  Want to pay in installments?
                </Form.Label>
                <Form.Check
                  style={{position: "relative", top: "2px"}}
                  inline
                  type={"checkbox"}
                  value={optionalAmount}
                  onClick={() => {
                    setOptionalAmount(!optionalAmount)
                  }}
                />
              </div> */}

              <div style={{textAlign: "right"}}>
                <Button
                  className="addItem mb-4"
                  onClick={() => addPaymentRecord()}
                  style={{ width: "200px", height: "50px", display: "inline-block" }}
                >
                  Add Paid Amount
                </Button>
              </div>

              {
                paymentRecord?.map((paidRecord, index) => {
                  let paymentRecordId = paidRecord._id;
                  let getDate = new Date(paidRecord.date);
                  getDate.setDate(getDate.getDate() + 1);
                  let currentDate = new Date();
                  let isPast = getDate < currentDate;
                  let disabled = paymentRecordId && isPast;
                  return (
                    <div key={index} aria-controls={`collapse${index}`}  aria-expanded={index === collapseIdx} style={{marginBottom: "20px"}}>
                      <div className="record-box" onClick={() => setCollapseIdx(index)}>
                        Paid Amount ({moment(paidRecord.date).format("DD/MMM/YYYY")})

                        <i className="fa fa-close" onClick={() => removePaymentRecord(index)}></i>
                      </div>
                      <Collapse in={index === collapseIdx}>
                        <div id={`collapse${index}`} style={{marginTop: "20px"}}>
                          <div>
                            <Form.Label className="black-label-text">
                              Payment Method
                            </Form.Label>
                            <div>
                              {optionPaymentMethods.length > 0 &&
                                optionPaymentMethods.map((meth) => {
                                  return (
                                    <>
                                      <Form.Check
                                        inline
                                        label={meth.name}
                                        type={"radio"}
                                        onClick={() => changePaymentRecord(index, "paymentMethod", meth.name)}
                                        checked={
                                          paidRecord.paymentMethod === meth.name
                                        }
                                        disabled={disabled}
                                      />
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                          
                          <Form.Group className="col-12">
                            <Form.Label className="black-label-text">
                              Payment Date
                            </Form.Label>
                            <DateInput
                              inputClassName="themeInput"
                              value={paidRecord.date || new Date()}
                              onChange={(newValue) => {
                                changePaymentRecord(
                                  index,
                                  "date",
                                  newValue,
                                );
                              }}
                              shouldDisableDates={true}
                              disabled={disabled}
                            />
                          </Form.Group>

                          <Form.Group className="col-12">
                            <Form.Label className="black-label-text">
                              Enter amount to pay
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                type="number"
                                className="hideArrows"
                                name="installmentAmount"
                                placeholder="Write..."
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setInstallmentAmount(value); // Update state for installmentAmount
                                  changePaymentRecord(index, "currentPaidAmount", value); // Update payment record
                                }}
                                value={paidRecord.currentPaidAmount} 
                                disabled={disabled}
                              />
                            </InputGroup>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label className="black-label-text">
                              Payment Remarks
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                name={"remarks"}
                                as="textarea"
                                rows={2}
                                placeholder="Write..."
                                style={{ resize: "none" }}
                                onChange={(e) => changePaymentRecord(index, "remarks", e.target.value)}
                                value={paidRecord.remarks}
                                disabled={disabled}
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </Collapse>
                    </div>
                  )
                })
              }
              
              <div style={{textAlign: "center"}}>
                <Button
                  className="addItem mt-4"
                  onClick={() => submitForm()}
                  style={{ width: "200px", height: "50px", display: "inline-block" }}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </PaymentWrapper>
    </div>
  )
}

export default AddPayment;