import React, { useEffect, useState, useMemo, useRef } from "react";
import { Button, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { BlobProvider, PDFViewer } from "@react-pdf/renderer";
import moment from "moment";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import {
  resetValue,
  setData,
  setForm,
} from "../../../redux/slices/StaffFormsSlices";
import _ from "lodash";
import SelectComponent from "../../SharedComponents/SelectComponent";
import InvoicePDF from "../../SharedComponents/InvoicePDF";
import ExternalPDF from "../../SharedComponents/ExternalPDF";
import AsyncSelect from "../../SharedComponents/AsyncSelect";
import {
  getFormatedTime,
  getInvoiceId,
  formatDate,
  calcTotalWithDisc,
} from "../../../helperFunctions/utils";
import PackagesTable from "./PackagesTable";
import DateInput from "../../SharedComponents/DateInput";
import {
  loadOptionPatients,
  loadSelectedPatient,
  getPatientDetailsById,
} from "../../../apis/patients";
import { getPaymentMethodList } from "../../../apis/paymentMethod";
import { getSetting, getExternalTemplate } from "../../../apis/generalSetting";
import { getPromo } from "../../../apis/promos";
import { setFormData } from "../../../redux/slices/commonSlice";

import { getEditBy } from "../../../apis/appointment";
import InternalPdf from "../../SharedComponents/InternalPdf";
import { getTransferByPatientId } from "../../../apis/billing";

export default function AddEditBilling() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const formDataChangedRef = useRef();
  const { state } = location;

  const { billingForm, selectedSession } = useSelector(
    (state) => state.staffForm
  );
  const isNewBilling = location.state?.newBilling;

  const [companySetting, setCompanySetting] = useState(null);
  const [setting, setSettings] = useState({});
  const [promo, setPromo] = useState(0);
  const [tax, setTax] = useState([]);
  const [usePoint, setUsePoint] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(subTotal);
  const [newRemark, setNewRemark] = useState("");
  const [newQty, setNewQty] = useState(0);
  const [newDisc, setNewDisc] = useState(0);
  const [newDiscType, setNewDiscType] = useState("Amount");
  const [itemToEdit, setItemToEdit] = useState();
  const [itemToEditPrice, setItemToEditPrice] = useState(0);
  const [itemToDel, setItemToDel] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [isFreeChannel, setIsFreeChannel] = useState(false);
  const [rewardPoints, setRewardPoints] = useState(0);
  const [status, setStatus] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [gst, setGst] = useState(0);
  const [programExpiration, setProgramExpiration] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethodName, setPaymentMethodName] = useState("");
  const [optionalAmount, setOptionalAmount] = useState(false);
  const [invoicePayment, setInvoicePayment] = useState(null);
  const [fileInvoice, setFileInvoice] = useState(null);
  const [externalTemplates, setExternalTemplates] = useState([]);
  const [modalExternal, setModalExternal] = useState();
  const [modalLabelPDF, setmodalLabelPDF] = useState();
  const [selectedLabel, setselectedLabel] = useState();
  const [listPromo, setListPromo] = useState([]);
  const [paymentAdjustments, setPaymentAdjustments] = useState([]);
  const [typeEdit, setTypeEdit] = useState("");
  const [isDelAdjustment, setIsDelAdjustment] = useState(false);
  const [selectedOptPatient, setSelectedOptPatient] = useState(null);
  const [remarkDiscount, setRemarkDiscount] = useState("");

  const [sessionInvoices, setSessionInvoices] = useState([]);
  const [membershipCategories, setMembershiCategories] = useState([]);
  const [pointsExpiry, setPointsExpiry] = useState();
  const [selectedMedicationCategory, setSelectedMedicationCategory] =
    useState(null);

  const [installmentAmount, setInstallmentAmount] = useState("");
  const [paymentRecord, setPaymentRecoed] = useState(null);
  const [allPaymentRecorde, setAllPaymentRecords] = useState([]);
  const [paymentInInstallment, setPaymentInInstallment] = useState(false);
  const [appointmentExist, setAppointmentExist] = useState(true);
  const [recalculateSub, setRecalculateSub] = useState(false);
  const [needInvoice, setNeedInvoice] = useState(false);
  const numberFormatter = Intl.NumberFormat("en-US");

  // const [extraProducts, setExtraProducts] = useState([]);
  // const [extraPackages, setExtraPackages] = useState([]);
  // const [extraServices, setExtraServices] = useState([]);

  const [showModal, setShowModal] = useState({
    medication: false,
    treatment: false,
    package: false,
    points: false,
    edit: false,
    other: false,
    adjustment: false,
  });
  const [tempSelectedSession, setTempSession] = useState({});
  const [invoicesArray, setInvoicesArray] = useState([]);
  const [isMember, setIsMember] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    value: "Percentage",
    label: "Percentage",
  });
  const [modalSelectedOption, setModalSelectedOption] = useState("");
  const [extraStates, setExtraStates] = useState({
    product_id: null,
    others_description: "",
    others_price: 0,
    product_quantity: null,
    product_unit_price: null,
    product_unit_cost: null,
    product_discount: 0,
    product_remark: "",
    product_price: null,
    product_cost: null,
    package_id: null,
    package_quantity: null,
    package_expiry: null,
    treatment_used: [],
    product_used: [],
    previous_package_unique_id: null,
    previous_package_id: null,
    // previous_package_billing_id: null,
    previous_treatment_used: [],
    previous_product_used: [],
    previous_billing_id: null,
    // package_points: 0,
    service_id: null,
    service_quantity: null,
    service_unit_price: null,
    service_remark: "",
    service_discount: 0,
    service_price: null,
  });
  const [sessions, setSessions] = useState([]);
  const [patientSession, setPatientSession] = useState();

  const [options, setOptions] = useState({
    patients: [],
    locations: [],
    branches: [],
    medications: [],
    packages: [],
    previousPackages: [],
    categoryCost: [],
    allPatients: [],
    campaigns: [],
    tax: [],
    paymentMethods: [],
    programs: [],
    status: [
      {
        label: "Paid",
        value: true,
      },
      {
        label: "Unpaid",
        value: false,
      },
    ],
    discountType: [
      { value: "Percentage", label: "Percentage" },
      { value: "Amount", label: "Amount" },
    ],
  });

  const pathname = location.pathname;
  const { formData } = useSelector((state) => state.commonData);

  useEffect(() => {
    if (!state?.dataId && !state.session) {
      formDataChangedRef.current = {
        session: selectedSession?.all_data?._id,
        patient: billingForm?.patient,
        branch: billingForm.branch,
        pointsExpiry: expiryDate,
        paymentMethod: paymentMethod,
        gst: gst,
        location: billingForm.location,
        recommend: billingForm.recommended ? billingForm.recommended : null,
        campaign: billingForm.campaign,
        others: [],
        categoryCosts: options.categoryCost
          ?.filter((o) => o.isChecked)
          ?.map((o) => o.id),
        tax: tax,
        treatment: [],
        product: [],
        package: [],
        sessionInvoices: sessionInvoices,
        usePoints: usePoint || 0,
        actualAmount: subTotal,
        totalAmount: total,
        remarks: billingForm.remarks,
        campaignRemarks: billingForm.campaignRemarks,
        isFree,
        isFreeChannel,
        rewardPoints: rewardPoints ? rewardPoints : null,
        isPaymentCompleted: status,
        promo,
        promoType: selectedOption?.value,
        isPaymentInInstallment: optionalAmount,
        installmentAmount: +installmentAmount,
        paymentMethodName,
        programExpiration,
        paymentAdjustments: paymentAdjustments,
        remarkDiscount: remarkDiscount,
      };
    }
  }, [
    billingForm,
    selectedSession,
    expiryDate,
    paymentMethod,
    gst,
    options,
    tax,
    sessionInvoices,
    usePoint,
    subTotal,
    total,
    isFree,
    isFreeChannel,
    rewardPoints,
    status,
    promo,
    selectedOptPatient,
    optionalAmount,
    installmentAmount,
    paymentMethodName,
    programExpiration,
    paymentAdjustments,
    remarkDiscount,
  ]);

  useEffect(() => {
    return () => {
      if (!state?.dataId && !state.session && formDataChangedRef.current) {
        dispatch(
          setFormData({
            ...formData,
            [pathname]: formDataChangedRef.current,
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    if (!state?.dataId && !state.session) {
      const reduxData = formData?.[pathname];
      if (reduxData && !state?.dataId) {
        dispatch(
          setForm({
            parent: "billingForm",
            child: "branch",
            value: reduxData?.branch,
          })
        );
        dispatch(
          setForm({
            parent: "billingForm",
            child: "location",
            value: reduxData?.location,
          })
        );
        dispatch(
          setForm({
            parent: "billingForm",
            child: "patient",
            value: reduxData?.patient,
          })
        );
        setTax(
          Array.isArray(reduxData?.tax) ? reduxData?.tax : [reduxData?.tax]
        );
        setUsePoint(reduxData?.usePoints);
        setRemarkDiscount(reduxData?.remarkDiscount);
        setRewardPoints(reduxData?.rewardPoints);
        setStatus(reduxData?.status);
        setPaymentMethod(reduxData?.paymentMethod);
        setPaymentMethodName(reduxData?.paymentMethodName);
        setPaymentAdjustments(reduxData?.paymentAdjustments);
        setPromo(reduxData?.promo);
        if (reduxData?.promoType === "Amount") {
          setSelectedOption({ value: "Amount", label: "Amount" });
        }
        setSessionInvoices(reduxData?.sessionInvoices);
        dispatch(
          setForm({
            parent: "billingForm",
            child: "recommended",
            value: reduxData?.recommend,
          })
        );
        dispatch(
          setForm({
            parent: "billingForm",
            child: "campaign",
            value: reduxData?.campaign,
          })
        );
        dispatch(
          setForm({
            parent: "billingForm",
            child: "remarks",
            value: reduxData?.remarks,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    getSetting().then((res) => {
      if (res.status !== "Fail") {
        setCompanySetting(res);
      }
    });
  }, []);

  useEffect(() => {
    getExternalTemplate().then((res) => {
      if (res.status !== "Fail") {
        setExternalTemplates(res);
      }
    });
  }, []);

  const setModal = (name) => {
    setShowModal({
      medication: name === "medication" ? !showModal?.medication : false,
      treatment: name === "treatment" ? !showModal?.treatment : false,
      package: name === "package" ? !showModal?.package : false,
      points: name === "points" ? !showModal?.points : false,
      edit: name === "edit" ? !showModal?.edit : false,
      other: name === "other" ? !showModal?.other : false,
      adjustment: name === "adjustment" ? !showModal?.adjustment : false,
    });
  };

  const onFormChange = (name, value) => {
    dispatch(setForm({ parent: "billingForm", child: name, value }));
  };
  const submitForm = async (haveInvoice) => {
    // if (sessionInvoices?.length == 0) {
    //   toastify("Please add at least 1 item", "error");
    //   return;
    // }

    if (+installmentAmount === 0 && optionalAmount === true) {
      return toastify("Amount you entered cannot be zero", "error");
    }

    const purchasingProduct = sessionInvoices.filter(
      (o) => o.type == "PRODUCT"
    );
    const availProducts = options.medications.find(
      (m) => m.id === purchasingProduct[0]?.id
    );
    // if (+purchasingProduct[0]?.qty > availProducts?.qty) {
    //   toastify(`${purchasingProduct[0]?.name} stock is not enough`, "error");
    // }

    const extraService = sessionInvoices
      .filter((o) => o.type == "SERVICE")
      .map((o) => {
        return {
          service_id: o.id,
          name: o.name,
          service_qty: parseInt(o.qty),
          service_remark: o.remark,
          service_discount: o.discount,
          service_discountType: o.discountType,
          service_price: o.total_price,
          service_unit_price: o.price,
          promoDiscountType: o.promoDiscountType,
          promoDiscountValue: o.promoDiscountValue,
          promoName: o.promoName,
        };
      });

    const extraProduct = sessionInvoices
      .filter((o) => o.type == "PRODUCT")
      .map((o) => {
        return {
          product_id: o.id,
          name: o.name,
          product_qty: parseInt(o.qty),
          product_discount: o.discount,
          product_remark: o.remark,
          product_discountType: o.discountType,
          product_price: o.total_price,
          product_unit_cost: o.cost,
          product_cost: o.cost ? parseInt(o.qty) * o.cost : 0,
          product_unit_price: o.price,
          promoDiscountType: o.promoDiscountType,
          promoDiscountValue: o.promoDiscountValue,
          promoName: o.promoName,
        };
      });

    const extraPackage = sessionInvoices
      .filter((o) => o.type == "PACKAGE")
      .map((o) => {
        return {
          package_id: o.isAlreadyPaid ? o.id.split(",")[0] : o.id,
          name: o.name,
          isAlreadyPaid: o.isAlreadyPaid ?? false,
          billingId: o.billingId,
          package_effective: o.effectiveDate,
          package_expiry: o.expiredDate,
          // points: parseInt(o.points) || 0,
          package_qty: parseInt(o.qty) || 1,
          package_discount: o.discount || 0,
          package_discountType: o.discountType,
          package_remark: o.remark,
          promoDiscountType: o.promoDiscountType,
          promoDiscountValue: o.promoDiscountValue,
          promoName: o.promoName,
          package_price: o.total_price,
          package_unit_price: o.price,
          isLinked: o.isLinked ? o.isLinked : false,
          treatments_used:
            o?.treatments?.length > 0
              ? [
                  ...o.treatments?.map((p) => ({
                    id: p.id,
                    used: p.used,
                    amount: p.new_price ? Number(p.new_price) : p.total_price
                  })),
                ]
              : [],
          products_used:
            o?.products?.length > 0
              ? [
                  ...o.products?.map((p) => ({
                    id: p.id,
                    used: p.used,
                    amount: p.new_price ? Number(p.new_price) : p.total_price
                  })),
                ]
              : [],
        };
      });
    const others = sessionInvoices
      ?.filter((o) => o.type == "OTHER")
      ?.map((o) => {
        return {
          description: o.description,
          total_price: o?.total_price,
        };
      });
    const data = {
      session: selectedSession?.all_data?._id,
      patient: billingForm?.patient,
      branch: billingForm.branch,
      pointsExpiry: expiryDate,
      paymentMethod: paymentMethod,
      gst: gst,
      location: billingForm.location,
      recommend: billingForm.recommended ? billingForm.recommended : null,
      campaign: billingForm.campaign,
      others,
      categoryCosts: options.categoryCost
        ?.filter((o) => o.isChecked)
        ?.map((o) => o.id),
      tax: tax,
      treatment: [...extraService],
      product: [...extraProduct],
      package: [...extraPackage],
      usePoints: usePoint || 0,
      actualAmount: subTotal,
      totalAmount: total,
      remarks: billingForm.remarks,
      campaignRemarks: billingForm.campaignRemarks,
      isFree,
      isFreeChannel,
      rewardPoints: rewardPoints ? rewardPoints : null,
      isPaymentCompleted: status,
      promo,
      promoType: selectedOption?.value,
      isPaymentInInstallment: optionalAmount,
      installmentAmount: +installmentAmount,
      paymentMethodName,
      programExpiration,
      paymentAdjustments: paymentAdjustments,
      remarkDiscount: remarkDiscount,
      preInvoiceId: location?.state?.preInvoice,
      conversionId: location?.state?.converssionId,
    };

    if (location?.state?.formStatus != "Edit" && !paymentInInstallment) {
      const { result, error } = await authorizedRequests({
        url: "payment/createPayment",
        method: "post",
        data: data,
      });
      if (error) {
        setInvoicePayment(null);
        toastify(error.message, "error");
        return;
      }
      toastify("Billing created succesfully", "success");
      getPaymentAfterSubmit(result, haveInvoice);
      // setInvoicePayment(true);
    } else if (paymentInInstallment) {
      const payload = {
        totalAmount: paymentRecord.totalAmount,
        payed: paymentRecord.payed + +installmentAmount,
        remaining: (paymentRecord.remaining - +installmentAmount).toFixed(2),
        paymentMethod: paymentMethodName,
        currentPaidAmount: +installmentAmount,
        programExpiration,
      };
      const { result, error } = await authorizedRequests({
        url: "payment/updatePayment",
        method: "post",
        data: {
          installmentRecord: payload,
          sessionId: location.state.session._id,
        },
      });
      if (result) {
        getPaymentAfterSubmit(result, haveInvoice);
        toastify("Payment successfully updated", "success");
      }
    } else {
      const { result, error } = await authorizedRequests({
        url: "payment/updatePayment",
        method: "post",
        data: { ...data, dataId: location?.state?.dataId },
      });
      if (error) {
        setInvoicePayment(null);
        toastify(error.message, "error");
        return;
      }

      getPaymentAfterSubmit(result, haveInvoice);
      toastify("Billing updated succesfully", "success");
    }
    return;
  };

  const onExtraFormChange = (name, value) => {
    setExtraStates((val) => {
      return { ...val, [name]: value };
    });
  };
  const getCalculatedPrice = (
    id,
    array,
    quantity,
    discount,
    discountType,
    name
  ) => {
    const item_price = array.find((o) => o.id == id).price * quantity;
    if (!discount || discount == 0) {
      onExtraFormChange(name, item_price);
      return;
    } else if (discountType === "Percentage") {
      const disc_price = item_price * (1 - discount / 100);
      onExtraFormChange(name, disc_price);
    } else {
      const disc_price = item_price - discount;
      onExtraFormChange(name, disc_price);
    }
  };

  const returnCalculatedPrice = (total_price, promoDiscountValue, promoDiscountType) => {
    if (newDiscType === "Percentage") {
      const totalPrice = calcTotalWithDisc(
        total_price,
        promoDiscountValue,
        promoDiscountType
      );
      const disc_price = totalPrice * (1 - newDisc / 100);
      return disc_price;
    } else {
      const disc_price =
        calcTotalWithDisc(
          total_price,
          promoDiscountValue,
          promoDiscountType
        ) - newDisc;
      return disc_price;
    }
  };

  const getValidPromo = (field, id) => {
    const selectedPromo = listPromo.find(
      (p) => p[field].filter((pp) => pp._id === id).length > 0
    );
    if (!selectedPromo) {
      return null;
    }

    const today = moment();
    const startDate = moment(selectedPromo.vaildStartDate);
    const endDate = moment(selectedPromo.vaildEndDate);
    if (today.isBetween(startDate, endDate, null, "[]")) {
      return selectedPromo;
    }

    return null;
  };

  const [patientTransfer, setPatientTransfer] = useState([]);
  const handleTransferData = async () => {
    console.log("i am here");
    const { result, error } = await getTransferByPatientId({
      id: billingForm?.patient,
    });
    if (error) {
      toastify(error.message, "error");
      return;
    }

    let tempArr = result?.data?.transfer
      ?.map((o) => {
        const all_packages = o.packages
          ?.filter((k) => {
            let isNotExpired = false;
            if (new Date(k.expiredDate).getTime() >= new Date().getTime()) {
              isNotExpired = true;
            }
            return isNotExpired;
          })
          ?.map((k) => {
            console.log({
              billingId: o?._id,
              packageId: k?.packageId,
              label: `${k?.packageName ? k?.packageName : k?.packageName} ${
                k?.promoName ? `(${k?.promoName})` : ""
              } ${o?.date ? `(Invoice Date ${formatDate(o?.date)})` : ""}`,
              value: `${k?.packageId},${o.invoiceId}`,
              discount: 0,
              remark: "",
              id: o?._id,
              price: 0,
              qty: 1,
              isLinked: true,
              isAlreadyPaid: true,
              treatments: k?.products
                ?.filter((a) => a?.type !== "Product")
                ?.map((treatment) => {
                  return {
                    id: treatment?.id,
                    name: treatment?.name,
                    qty: treatment.qty,
                    unit_price: 0,
                    discount: 0,
                    total_price: 0,
                    used: treatment?.used ?? 0,
                  };
                }),
              products: k?.products
                ?.filter((a) => a?.type === "Product")
                ?.map((product) => {
                  return {
                    id: product?.id,
                    name: product?.name,
                    qty: product.qty,
                    unit_price: 0,
                    unit_cost: 0,
                    discount: 0,
                    total_price: 0,
                    used: product?.used ?? 0,
                  };
                }),
            });

            return {
              billingId: o?._id,
              packageId: k?.packageId,
              label: `${k?.packageName ? k?.packageName : k?.packageName} ${
                k?.promoName ? `(${k?.promoName})` : ""
              } ${o?.date ? `(Invoice Date ${formatDate(o?.date)})` : ""}`,
              value: `${k?.packageId},${o.invoiceId}`,
              discount: 0,
              remark: "",
              id: o?._id,
              price: 0,
              qty: 1,
              isLinked: true,
              isAlreadyPaid: true,
              treatments: k?.products
                ?.filter((a) => a?.type !== "Product")
                ?.map((treatment) => {
                  return {
                    id: treatment?.id,
                    name: treatment?.name,
                    qty: treatment.qty,
                    unit_price: 0,
                    discount: 0,
                    total_price: 0,
                    used: treatment?.used ?? 0,
                  };
                }),
              products: k?.products
                ?.filter((a) => a?.type === "Product")
                ?.map((product) => {
                  return {
                    id: product?.id,
                    name: product?.name,
                    qty: product.qty,
                    unit_price: 0,
                    unit_cost: 0,
                    discount: 0,
                    total_price: 0,
                    used: product?.used ?? 0,
                  };
                }),
            };
          });

        console.log(all_packages);

        return [...all_packages];
      })
      ?.flat();

    console.log(result?.data?.transfer);

    setPatientTransfer(tempArr ?? []);
  };
  console.log(patientTransfer);

  const getPreviousPackages = async () => {
    const { result, error } = await authorizedRequests({
      url: "payment/listPreviousPackagesByPatientId",
      method: "post",
      data: {
        patientId: billingForm.patient,
      },
    });
    if (error) {
      toastify(error.message, "error");
      return;
    }
    handleTransferData();
    let tempArr = result.data.map((o) => {
      const all_packages = o.package
        ?.filter((k) => {
          let isNotExpired = false;
          k.package_expiry.map((e) => {
            if (new Date(e).getTime() >= new Date().getTime()) {
              isNotExpired = true;
            }

            return false;
          });

          return (isNotExpired && !k.isConversion && !k.isDeleted);
        })
        ?.map((k) => {
          return {
            billingId: o._id,
            packageId: k?.package_id?._id,
            label: `${k?.name ? k?.name : k?.package_id?.name} ${
              k?.promoName ? `(${k?.promoName})` : ""
            } ${o?.date ? `(Invoice Date ${formatDate(o?.date)})` : ""}`,
            value: `${k?.package_id?._id},${o._id}`,
            discount: k?.package_discount || 0,
            remark: k?.package_remark || "",
            id: k?.package_id?._id,
            price: k?.package_price,
            qty: k?.package_qty,
            isLinked: true,
            isAlreadyPaid: true,
            treatments: k?.treatments?.map((treatment) => {
              const disc_price = treatment.disc_price;
              const product_used = k?.treatments_used?.find(
                (productUsed) => productUsed.id == treatment?.treatment?._id
              );

              return {
                id: treatment?.treatment?._id,
                name: treatment?.treatment?.name,
                qty: treatment.limit,
                unit_price: treatment?.treatment?.price,
                discount: treatment?.discount,
                ori_total_price: disc_price,
                total_price: product_used?.amount ?? disc_price,
                used: product_used?.used ?? 0,
              };
            }),
            products: k?.products?.map((product) => {
              const disc_price = product.totalPrice;
              const product_used = o?.products_used?.find(
                (productUsed) => productUsed.id == product?.product?._id
              );

              return {
                id: product?.product?._id,
                name: product?.product?.name,
                qty: product.qty,
                unit_price: product?.product?.unitSellingPrice,
                unit_cost: product?.product?.averageUnitCost,
                discount: product?.discount,
                ori_total_price: disc_price,
                total_price: product_used?.amount ?? disc_price,
                used: product_used?.used ?? 0,
              };
            }),
          };
        });
      return [...all_packages];
    });
    tempArr = _.flattenDeep(tempArr);
    setOptions((val) => {
      return { ...val, previousPackages: tempArr };
    });
  };
  const getBranches = async () => {
    const { result, error } = await authorizedRequests({
      url: "branch/listOfBranch",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "Failed to get branches", "error");
      return;
    }
    const tempArr = result.data.map((o) => {
      return {
        id: o._id,
        name: o.name,
        label: o.name,
        value: o._id,
        locations: o.location,
      };
    });
    setOptions((val) => {
      return { ...val, branches: tempArr };
    });
  };

  const getPaymentBySessionId = async () => {
    const payload = {
      sessionId: location.state?.session?._id,
    };
    const result = await authorizedRequests({
      url: "payment/getPaymentbySessionId",
      method: "post",
      data: payload,
    });
    if (!result.error) {
      const { paymentRecord, isPaymentInInstallment, paymentAdjustments } =
        result?.result?.data;
      setPaymentRecoed(paymentRecord[paymentRecord.length - 1]);
      setAllPaymentRecords(paymentRecord);
      if (paymentAdjustments?.length > 0) {
        setPaymentAdjustments(paymentAdjustments);
      }
      setPaymentInInstallment(isPaymentInInstallment);
    }
  };

  const getPaymentMethods = async () => {
    const { result, error } = await getPaymentMethodList();
    if (error) {
      toastify(error.message || "Failed to get payment", "error");
      return;
    }
    setOptions((val) => {
      return { ...val, paymentMethods: result.data };
    });
  };

  const getPatients = async () => {
    const { result, error } = await authorizedRequests({
      url: `session/getSessionByLocation/${billingForm.location}`,
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "Failed to get Patients", "error");
      return;
    }
    var tempPatientsArr = result.data.map((o) => {
      return {
        name: o?.appointment?.patient?.patientProfile?.fullName,
        id: o?.appointment?.patient?._id,
        label: o?.appointment?.patient?.patientProfile?.fullName,
        value: o?.appointment?.patient?._id,
        isMember: o?.appointment?.patient?.isMember,
        points: o?.appointment?.patient?.points,
        kol: o?.appointment?.patient?.patientProfile?.recommendFrom,
        patientProfile: o?.appointment?.patient?.patientProfile,
      };
    });

    tempPatientsArr = _.uniqWith(tempPatientsArr, _.isEqual);

    const tempAllPatientsArr = tempPatientsArr.map((x) => ({
      name: x.name,
      id: "",
      label: x.name,
      value: x.id,
      isMember: x.isMember,
      points: x.points,
      kol: "",
      patientProfile: x.patientProfile,
    }));

    const patientsArr = _.uniqWith(
      [...tempPatientsArr, ...tempAllPatientsArr],
      (arrVal, othVal) => {
        return arrVal.value === othVal.value;
      }
    );

    //edit by jonny
    if (billingForm?.patient) {
      const billingFormPatient = patientsArr?.find(
        (o) => o?.value == billingForm.patient
      );

      setIsMember(billingFormPatient?.isMember);
      setSelectedOptPatient(billingFormPatient);
    }

    setOptions((val) => {
      return { ...val, patients: patientsArr };
    });

    setSessions(result.data);
  };
  const getMedications = async () => {
    const { result, error } = await authorizedRequests({
      url: "product/listOfProducts",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "Failed to get products", "error");
      return;
    }

    let tempCategories = [];
    const tempArr = result?.data?.map((o) => {
      let selectedPromo = getValidPromo("products", o._id);
      const name = `${o?.name} ${
        selectedPromo ? `(Promo ${selectedPromo.name})` : ""
      }`;

      const CategoryIndex = tempCategories.findIndex(
        (c) => c._id == o?.type?._id
      );
      if (CategoryIndex == -1) {
        tempCategories.push({ ...o?.type });
      }
      return {
        id: o?._id,
        name: name,
        value: o?._id,
        label: name,
        promoDiscountType: selectedPromo ? selectedPromo.discountType : "",
        promoDiscountValue: selectedPromo ? selectedPromo.discountValue : 0,
        promoName: selectedPromo ? selectedPromo.name : "",
        qty: o?.qty,
        price: o?.unitSellingPrice,
        cost: o?.averageUnitCost,
        type: o?.type?.name,
        typeId: o?.type?._id,
        qty: o?.qty,
        expDate: o?.expDate,
        batchNumber: o?.batchNumber,
      };
    });

    setOptions((val) => {
      return { ...val, medications: tempArr };
    });
    setOptions((val) => {
      return { ...val, medicationCategories: tempCategories };
    });
  };
  const getTreatments = async () => {
    const { result, error } = await authorizedRequests({
      url: "treatment/listOfTreatment",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "Failed to get products", "error");
      return;
    }
    const tempArr = result?.data?.map((o) => {
      let selectedPromo = getValidPromo("treatments", o._id);
      const name = `${o?.name} ${
        selectedPromo ? `(Promo ${selectedPromo.name})` : ""
      }`;

      return {
        id: o?._id,
        name: name,
        value: o?._id,
        label: name,
        price: o?.price,
        promoDiscountType: selectedPromo ? selectedPromo.discountType : "",
        promoDiscountValue: selectedPromo ? selectedPromo.discountValue : 0,
        promoName: selectedPromo ? selectedPromo.name : "",
      };
    });

    setOptions((val) => {
      return { ...val, treatments: tempArr };
    });
  };
  const getPromoCodeList = async () => {
    const response = await getPromo();
    if (response?.status !== "Fail") {
      setListPromo(response.reverse());
    } else {
      if (response?.message === "No data Found") {
        setListPromo([]);
      }
    }
  };
  const getPackages = async () => {
    const { result, error } = await authorizedRequests({
      url: "package/listOfPackages",
      method: "get",
      params: {
        isValidDate: true,
        isDeleted: false,
        currentDate: new Date().toISOString(),
      },
    });
    if (error) {
      toastify(error.message ?? "Failed to get packages", "error");
      return;
    }

    const tempArr = result?.data?.map((o) => {
      let selectedPromo = getValidPromo("packages", o._id);
      const name = `${o?.name} ${
        selectedPromo ? `(Promo ${selectedPromo.name})` : ""
      }`;

      return {
        id: o?._id,
        name,
        value: o?._id,
        label: name,
        price: o.packagePrice,
        qty: 1,
        discount: 0,
        promoDiscountType: selectedPromo ? selectedPromo.discountType : "",
        promoDiscountValue: selectedPromo ? selectedPromo.discountValue : 0,
        promoName: selectedPromo ? selectedPromo.name : "",
        expiredDate: o?.expiredDate,
        effectiveDate: o?.effectiveDate,
        treatments: o?.treatments?.map((treatment) => {
          const disc_price = treatment?.disc_price;
          return {
            id: treatment?.treatment?._id,
            name: treatment?.treatment?.name,
            qty: treatment.limit,
            unit_price: treatment?.treatment?.price,
            discount: treatment?.discount,
            total_price: disc_price,
            // used: treatment.limit,
            used: 0,
          };
        }),
        products: o?.products?.map((product) => {
          const disc_price = product?.totalPrice;
          return {
            id: product?.product?._id,
            name: product?.product?.name,
            qty: product.qty,
            unit_price: product?.product?.unitSellingPrice,
            discount: product?.discount,
            total_price: disc_price,
            // used: product.qty,
            used: 0,
          };
        }),
      };
    });
    setOptions((val) => {
      return { ...val, packages: tempArr };
    });
  };
  const getCategoryCost = async () => {
    const { result, error } = await authorizedRequests({
      url: "categoryCost/listOfcategoryCost",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "Failed to get category cost", "error");
      return;
    }
    const tempArr = result?.data?.map((o) => {
      return {
        id: o._id,
        name: o.name,
        isChecked:
          location?.state?.categoryCosts?.length > 0
            ? location?.state?.categoryCosts?.indexOf(o._id) !== -1
            : false,
      };
    });
    setOptions((val) => {
      return { ...val, categoryCost: tempArr };
    });
  };
  const getAllPatients = async () => {
    // const { result, error } = await authorizedRequests({
    // 	url: "patient/listOfPatient",
    // 	method: "get",
    // });
    // if (error) {
    // 	toastify(error.message ?? "Failed to get patients", "error");
    // 	return;
    // }
    // const tempArr = result?.data?.list?.map((o) => {
    // 	return {
    // 		id: o._id,
    // 		name: o?.patientProfile?.fullName,
    // 		value: o._id,
    // 		label: o?.patientProfile?.fullName,
    // 		points: o?.points || 0,
    // 		pointsExpiry: new Date(o?.pointsExpiry),
    // 		dob: new Date(o?.patientProfile?.dob),
    // 		isMember: o?.isMember,
    // 	};
    // });
    // setOptions((val) => {
    // 	return { ...val, allPatients: tempArr };
    // });
  };
  const getCampaigns = async () => {
    const { result, error } = await authorizedRequests({
      url: "compaign/listOfCompaign",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "Failed to get campaigns", "error");
      return;
    }
    const tempArr = result?.data?.map((o) => {
      return {
        id: o._id,
        name: o.name,
        value: o._id,
        label: o.name,
      };
    });
    setOptions((val) => {
      return { ...val, campaigns: tempArr };
    });
  };
  const getTax = async () => {
    const { result, error } = await authorizedRequests({
      url: "taxSetting/listOfTaxSetting",
      method: "get",
    });
    if (error) {
      toastify(error.message, "error");
      return;
    }
    const tempArr = result?.data?.map((o) => {
      return {
        id: o._id,
        name: o.name,
        type: o.typeValue,
        value: o._id,
        taxValue: o.value,
        label: o.name,
      };
    });

    setOptions((val) => {
      return { ...val, tax: tempArr };
    });

    if (tax.length == 0) {
      const getGST = tempArr.find((o) => o?.name?.toLowerCase() == "gst")?.id;
      if (getGST) {
        setTax([getGST]);
      }
    }
  };
  const getMembershipSetting = async () => {
    const { result, error } = await authorizedRequests({
      url: "membershipSetting/listOfMembershipSetting",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "Failed to get membership settings", "error");
      return;
    }
    setSettings({
      value: result.data[0].point,
      conversion: result.data[0].conversion,
      memberExpiry: result.data[0].expForMember,
      nonMemberExpiry: result.data[0].expForNonMember,
      isDouble: result.data[0]?.isDouble,
      rewardPoint: result?.data[0]?.rewardPoint,
      rewardAmount: result?.data[0]?.rewardAmount,
    });
  };
  const getMembershipCategories = async () => {
    const { result, error } = await authorizedRequests({
      url: "membershipCategory/listOfMembershipCategory",
      method: "get",
    });
    if (error) {
      toastify("Failed to get membership categories", "error");
      return;
    }
    setMembershiCategories(result?.data);
  };
  const getTotalSum = (arr) => {
    let sum = 0;
    arr
      ?.filter((o) => !o.isAlreadyPaid)
      ?.forEach((o) => {
        if (!isNaN(Number(o.total_price))) sum += Number(o.total_price);
      });
    return sum;
  };

  const makeSessionForNewBilling = async () => {
    const { result, error } = await authorizedRequests({
      url: "session/getPatientTodaySession",
      method: "post",
      data: {
        patientID: selectedPatient,
        location: billingForm.location,
        branch: billingForm.branch,
      },
    });
    if (error) {
      setAppointmentExist(false);
      // toastify(error.message, "error");
      return;
    }
    dispatch(
      setData({
        parent: "selectedSession",
        value: {
          all_data: result?.data,
        },
      })
    );
    // toastify("Session created succesfully", "success");
  };
  const getProgramExpiration = async () => {
    const { result, error } = await authorizedRequests({
      url: "program/listPrograms",
      method: "get",
    });
    if (error) {
      toastify(error.message, "error");
      return;
    }
    const tempArr = result?.data?.map((o) => {
      return {
        id: o._id,
        name: o.name,
        description: o.description,
        startDate: o.startDate,
        expiryDate: o.expiryDate,
        label: o.name,
        value: o._id,
      };
    });
    setOptions((val) => {
      return { ...val, programs: tempArr };
    });
  };

  useEffect(() => {
    getPromoCodeList();
  }, []);

  useEffect(() => {
    getBranches();
    getMedications();
    getPackages();
    getTreatments();
    getCategoryCost();
    getAllPatients();
    getCampaigns();
    getMembershipSetting();
    getMembershipCategories();
    getTax();
    getPaymentMethods();
    getProgramExpiration();
  }, [listPromo]);

  useEffect(() => {
    getPaymentBySessionId();
  }, [location]);

  useEffect(() => {
    if (isNewBilling && selectedPatient && selectedPatient !== "") {
      makeSessionForNewBilling();
    }
  }, [selectedPatient]);

  useMemo(() => {
    // if (isFree) {
    // 	setTotal(0);
    // } else {
    // if (subTotal > 0) {
      // debugger;
      let amount = Number(subTotal);
      selectedOption?.value === "Percentage"
        ? (amount = amount * (1 - (promo ?? 0) / 100))
        : (amount = amount - promo ?? 0);
      // amount = amount * (1 - (promo ?? 0) / 100);
      let percentTax = 0;
      let exactTax = 0;
      tax?.forEach((singleTax) => {
        const t = options.tax.find((o) => o.id == singleTax);
        if (t && t.type == "Percentage") {
          percentTax += t.taxValue;
          // amount = amount + (amount * t.taxValue) / 100;
        } else if (t && t.type !== "Percentage") {
          exactTax += t.taxValue;
          // amount = amount + t.taxValue;
        }
      });

      amount =
        amount -
        usePoint * ((setting?.conversion || 0) / (setting?.value || 0));

      paymentAdjustments?.map((adjustment) => {
        if (adjustment.discountType === "Percentage") {
          amount = amount - (subTotal * adjustment.discount) / 100;
        } else {
          amount = amount - adjustment.discount;
        }
      });
      setTotal(parseFloat(amount).toFixed(2));

      amount = amount + (amount * percentTax) / 100;
      amount = amount + exactTax;
      setGst(parseFloat(amount).toFixed(2));
    // } else if (Number(subTotal) === 0) {
    //   setTotal(0);
    //   setGst(0);
    // }
    // }
  }, [
    subTotal,
    tax,
    promo,
    usePoint,
    setting?.value,
    options?.tax,
    isFree,
    isFreeChannel,
    selectedOption,
    paymentAdjustments,
  ]);

  useMemo(() => {
    if (billingForm.branch && options.branches?.length > 0) {
      const loc = options.branches.find((o) => o.id == billingForm.branch);
      setOptions((val) => {
        return {
          ...val,
          locations: loc?.locations.map((o) => {
            return {
              id: o._id,
              name: o.name,
              label: o.name,
              value: o._id,
            };
          }),
        };
      });
    }
  }, [billingForm.branch, options.branches]);
  useMemo(() => {
    if (billingForm.location) {
      getPatients();
    }
  }, [billingForm.location]);
  useMemo(() => {
    if (billingForm.patient) {
      getPreviousPackages();
    }
  }, [billingForm.patient]);
  useMemo(() => {
    if (
      billingForm.patient &&
      options.patients.length > 0 &&
      membershipCategories.length > 0
    ) {
      const patMembershitp = membershipCategories.find(
        (o) => o.startFrom <= selectedOptPatient?.points ?? 0
      );
      if (patMembershitp?._id) {
        setPointsExpiry(setting.memberExpiry);
      } else {
        setPointsExpiry(setting.nonMemberExpiry);
      }
    }
  }, [options.patients, billingForm.patient, membershipCategories, setting]);
  useMemo(() => {
    const today = new Date();
    if (
      selectedOptPatient &&
      selectedOptPatient?.isMember
      // selectedOptPatient?.patientProfile?.dob
    ) {
      const patientDOB = new Date(selectedOptPatient?.patientProfile?.dob);
      if (total && setting.rewardAmount && setting.rewardPoint) {
        // let tempPoint = subTotal * setting.value;
        let tempPoint =
          Math.ceil(subTotal / setting.rewardAmount) * setting.rewardPoint;
        if (
          setting.isDouble &&
          patientDOB !== "Invalid Date" &&
          today.getMonth() === patientDOB.getMonth() &&
          today.getDate() === patientDOB.getDate()
        ) {
          tempPoint = tempPoint * 2;
        }
        setRewardPoints(tempPoint);
      }
    }
  }, [setting.rewardPoint, setting.isDouble, total, billingForm.patient]);
  useMemo(() => {
    if (billingForm.patient && billingForm.isNew) {
      const requiredSessions = sessions.filter(
        (o) => o?.appointment?.patient?._id == billingForm?.patient
      );

      const tempArr = requiredSessions.map((o) => {
        return {
          id: o._id,
          startTime: o.startTime,
          status: o.status,
          endTime: o.endTime,
          date: o.date
            ? new Date(o?.date).toLocaleDateString()
            : "Date not found",
          staff: o.staff?.map((k) => {
            return {
              id: k._id,
              name: k.username,
            };
          }),
          all_data: { ...o },
        };
      });
      setPatientSession(tempArr);
    }
  }, [billingForm.patient, billingForm.isNew]);
  useMemo(() => {
    if (extraStates.product_id && extraStates.product_quantity) {
      getCalculatedPrice(
        extraStates.product_id,
        options.medications,
        extraStates.product_quantity,
        null,
        "",
        "product_price"
      );
    }
  }, [extraStates.product_id, extraStates.product_quantity]);
  useMemo(() => {
    if (extraStates.service_id && extraStates.service_quantity) {
      getCalculatedPrice(
        extraStates.service_id,
        options.treatments,
        extraStates.service_quantity,
        null,
        "",
        "service_price"
      );
    }
  }, [extraStates.service_id, extraStates.service_quantity]);

  useMemo(() => {
    if (!selectedSession) {
      if (location?.state?.formStatus == "Edit") {
        const servicesArr = location?.state?.treatment?.map((o) => {
          return {
            ...o,
            type: "SERVICE",
            total_price: o.service_price,
            qty: o.service_qty,
            remark: o.service_remark,
            discount: o.service_discount,
            discountType: o.service_discountType,
            price: o.service_unit_price,
            name: o?.service_id?.name,
            id: o?.service_id?._id,
          };
        });

        const productsArr = location?.state?.product?.map((o) => {
          return {
            ...o,
            type: "PRODUCT",
            total_price: o.product_price,
            qty: o.product_qty,
            discount: o.product_discount,
            remark: o.product_remark,
            discountType: o.product_discountType,
            price: o.product_unit_price,
            cost: o.product_unit_cost,
            name: o?.product_id?.name,
            id: o?.product_id?._id,
          };
        });

        const packagesArr = location?.state?.package?.map((o) => {
          return {
            ...o,
            type: "PACKAGE",
            points: o?.points,
            expiredDate: o.package_expiry,
            products: o.package_id.products.map((k) => {
              const disc_price = k?.totalPrice;
              const product_used = o?.products_used?.find(
                (productUsed) => productUsed.id == k?.product?._id
              );

              return {
                id: k?.product?._id,
                name: k?.product?.name,
                qty: k.qty,
                unit_price: k?.product?.unitSellingPrice,
                discount: k?.discount,
                ori_total_price: disc_price,
                total_price: product_used?.amount ?? disc_price,
                used: product_used?.used,
              };
            }),
            treatments: o?.package_id?.treatments?.map((k) => {
              const disc_price = k?.disc_price;
              const product_used = o?.treatments_used?.find(
                (productUsed) => productUsed.id == k?.treatment?._id
              );

              console.log("DEBUG", k, product_used, product_used?.amount)
              console.log("DEBUG", {
                id: k?.treatment?._id,
                name: k?.treatment?.name,
                qty: k.limit,
                unit_price: k?.treatment?.price,
                discount: k?.discount,
                ori_total_price: disc_price,
                total_price: product_used?.amount ?? disc_price,
                used: product_used?.used
              })

              return {
                id: k?.treatment?._id,
                name: k?.treatment?.name,
                qty: k.limit,
                unit_price: k?.treatment?.price,
                discount: k?.discount,
                ori_total_price: disc_price,
                total_price: product_used?.amount ?? disc_price,
                used: product_used?.used,
              };
            }),
            total_price: o.package_price,
            qty: o.package_qty,
            discount: o.package_discount,
            remark: o.package_remark,
            price: o.package_unit_price,
            id: o?.package_id?._id,
            name: o?.package_id?.name,
            isAlreadyPaid: o?.isAlreadyPaid,
          };
        });

        console.log("DEBUG", packagesArr)

        const others = location?.state?.others?.map((o) => {
          return { ...o, type: "OTHER" };
        });

        if (location?.state?.paymentAdjustments) {
          setPaymentAdjustments(location?.state?.paymentAdjustments);
        }

        const tempArr = [
          ...servicesArr,
          ...packagesArr,
          ...productsArr,
          ...others,
        ];
        setSessionInvoices(tempArr);
      }
    }
    if (selectedSession) {
      if (location?.state?.formStatus == "Edit") {
        const servicesArr = location?.state?.treatment?.map((o) => {
          return {
            ...o,
            type: "SERVICE",
            total_price: o.service_price,
            qty: o.service_qty,
            remark: o.service_remark,
            discount: o.service_discount,
            discountType: o.service_discountType,
            price: o.service_unit_price,
            name: o?.name ? o?.name : o?.service_id?.name,
            id: o?.service_id?._id,
          };
        });
        const productsArr = location?.state?.product?.map((o) => {
          return {
            ...o,
            type: "PRODUCT",
            total_price: o.product_price,
            qty: o.product_qty,
            discount: o.product_discount,
            remark: o.product_remark,
            discountType: o.product_discountType,
            price: o.product_unit_price,
            cost: o.product_unit_cost,
            name: o?.name ? o?.name : o?.product_id?.name,
            id: o?.product_id?._id,
          };
        });

        const packagesArr = location?.state?.package?.map((o) => {
          return {
            ...o,
            type: "PACKAGE",
            expiredDate: o.package_expiry,
            points: o?.points,
            total_price: o.package_price,
            qty: o.package_qty,
            discount: o.package_discount,
            remark: o.package_remark,
            price: o.package_unit_price,
            id: o?.package_id?._id,
            name: o?.name ? o?.name : o?.package_id?.name,
            isAlreadyPaid: o?.isAlreadyPaid,
            products: o.package_id.products.map((k) => {
              const disc_price = k?.totalPrice;
              const product_used = o?.products_used?.find(
                (productUsed) => productUsed.id == k?.product?._id
              );

              return {
                id: k?.product?._id,
                name: k?.product?.name,
                qty: k.qty,
                unit_price: k?.product?.unitSellingPrice,
                discount: k?.discount,
                ori_total_price: disc_price,
                total_price: product_used?.amount ?? disc_price,
                used: product_used?.used,
              };
            }),
            treatments: o?.package_id?.treatments?.map((k) => {
              const disc_price = k?.disc_price;
              const product_used = o?.treatments_used?.find(
                (productUsed) => productUsed.id == k?.treatment?._id
              );

              return {
                id: k?.treatment?._id,
                name: k?.treatment?.name,
                qty: k.limit,
                unit_price: k?.treatment?.price,
                discount: k?.discount,
                ori_total_price: disc_price,
                total_price: product_used?.amount ?? disc_price,
                used: product_used?.used,
              };
            }),
          };
        });

        const others = location?.state?.others?.map((o) => {
          return { ...o, type: "OTHER" };
        });

        if (location?.state?.paymentAdjustments) {
          setPaymentAdjustments(location?.state?.paymentAdjustments);
        }

        const tempArr = [
          ...servicesArr,
          ...packagesArr,
          ...productsArr,
          ...others,
        ];
        setSessionInvoices(tempArr);
      } else {
        const prescriptionDrugs =
          selectedSession?.all_data?.patientPrescriptionSheet
            ?.filter((x) => x.type === "internal")
            ?.map((o) => {
              return {
                qty: o.quantity,
                discount: o.discount,
                price: o.unitPrice,
                cost: o.unitCost,
                total_price: o.unitPrice * o.quantity,
                type: "PRODUCT",
                name: o.drugName,
                id: o.drugId,
                typeDrug: o.type,
              };
            });

        const tempArr = [
          ...sessionInvoices.filter((o) => o.isExtra),
          // ...productsArr,
          ...(prescriptionDrugs || []),
        ];
        setSessionInvoices(tempArr);
      }
    }
    if (location?.state?.isConversion) {
      const servicesArr = location?.state?.treatment?.map((o) => {
        return {
          ...o,
          type: "SERVICE",
          total_price: o.service_price,
          qty: o.service_qty,
          remark: o.service_remark,
          discount: o.service_discount,
          discountType: o.service_discountType,
          price: o.service_unit_price,
          name: o?.name ? o?.name : o?.service_id?.name,
          id: o?.service_id?._id,
        };
      });
      const productsArr = location?.state?.product?.map((o) => {
        return {
          ...o,
          type: "PRODUCT",
          total_price: o.product_price,
          qty: o.product_qty,
          discount: o.product_discount,
          remark: o.product_remark,
          discountType: o.product_discountType,
          price: o.product_unit_price,
          cost: o.product_unit_cost,
          name: o?.name ? o?.name : o?.product_id?.name,
          id: o?.product_id?._id,
        };
      });

      const packagesArr = location?.state?.package?.map((o) => {
        return {
          ...o,
          type: "PACKAGE",
          expiredDate: o.package_expiry,
          points: o?.points,
          total_price: o.package_price,
          qty: o.package_qty,
          discount: o.package_discount,
          remark: o.package_remark,
          price: o.package_unit_price,
          id: o?.package_id?._id,
          name: o?.name ? o?.name : o?.package_id?.name,
          isAlreadyPaid: o?.isAlreadyPaid,
          products: o.package_id?.products?.map((k) => {
            const disc_price = k?.totalPrice;
            const product_used = o?.products_used?.find(
              (productUsed) => productUsed.id == k?.product?._id
            );

            return {
              id: k?.product?._id,
              name: k?.product?.name,
              qty: k.qty,
              unit_price: k?.product?.unitSellingPrice,
              discount: k?.discount,
              ori_total_price: disc_price,
              total_price: product_used?.amount ?? disc_price,
              used: product_used?.used,
            };
          }),
          treatments: o?.package_id?.treatments?.map((k) => {
            const disc_price = k?.disc_price;
            const product_used = o?.treatments_used?.find(
              (productUsed) => productUsed.id == k?.treatment?._id
            );
            return {
              id: k?.treatment?._id,
              name: k?.treatment?.name,
              qty: k.limit,
              unit_price: k?.treatment?.price,
              discount: k?.discount,
              ori_total_price: disc_price,
              total_price: product_used?.amount ?? disc_price,
              used: product_used?.used,
            };
          }),
        };
      });
      const others = location?.state?.others?.map((o) => {
        return { ...o, type: "OTHER" };
      });
      if (location?.state?.paymentAdjustments) {
        setPaymentAdjustments(location?.state?.paymentAdjustments);
      }
      dispatch(
        setForm({
          parent: "billingForm",
          child: "patient",
          value: location?.state?.patient?._id,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "recommended",
          value: location?.state?.patient?._id,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "branch",
          value: location?.state?.branch,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "location",
          value: location?.state?.location,
        })
      );
      const tempArr = [
        ...servicesArr,
        ...packagesArr,
        ...productsArr,
        ...others,
      ];

      setSessionInvoices(tempArr);
    }
  }, [
    selectedSession,
    location?.state?.session?.formStatus,
    location?.state?.treatment,
    location?.state?.package,
    location?.state?.product,
  ]);

  useMemo(() => {
    if (sessionInvoices.length > 0) {
      let t = getTotalSum(sessionInvoices);
      // if (t !== 0 && promo !== 0) {
      // 	t = t - (t * promo / 100);
      // }

      setSubTotal(t.toFixed(2));
    }
  }, [sessionInvoices, recalculateSub]);
  useMemo(() => {
    if (location?.state?.session) {
      dispatch(
        setForm({
          parent: "billingForm",
          child: "isNew",
          value: false,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "formStatus",
          value: location?.state?.formStatus || "New",
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "dataId",
          value: location?.state?.dataId || undefined,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "branch",
          value: location?.state?.session?.branch?._id,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "location",
          value: location?.state?.session?.location,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "patient",
          value: location?.state?.session?.appointment?.patient?._id,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "recommended",
          value:
            location?.state?.session?.appointment?.patient?.patientProfile
              ?.recommendFrom,
        })
      );
      dispatch(
        setData({
          parent: "selectedSession",
          value: {
            all_data: location?.state?.session,
          },
        })
      );
    }
    if (location?.state?.formStatus == "Edit") {
      dispatch(
        setForm({
          parent: "billingForm",
          child: "branch",
          value: location?.state?.branch,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "location",
          value: location?.state?.location,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "patient",
          value: location?.state?.patient?._id,
        })
      );
      setTax(
        Array.isArray(location?.state?.tax)
          ? location?.state?.tax
          : [location?.state?.tax]
      );
      // setTotal(location?.state?.totalAmount);
      setUsePoint(location?.state?.usePoints);
      setRemarkDiscount(location?.state?.remarkDiscount);
      setRewardPoints(location?.state?.rewardPoints);
      setStatus(location?.state?.status);
      setPaymentMethod(location?.state?.paymentMethod?._id);
      setPaymentMethodName(location?.state.paymentMethod?.name);
      setPromo(location?.state?.promo);
      if (location?.state?.promoType === "Amount") {
        setSelectedOption({ value: "Amount", label: "Amount" });
      }
      dispatch(
        setForm({
          parent: "billingForm",
          child: "recommended",
          value: location?.state?.recommend,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "campaign",
          value: location?.state?.campaign,
        })
      );
      dispatch(
        setForm({
          parent: "billingForm",
          child: "remarks",
          value: location?.state?.remarks,
        })
      );
    }
  }, [location?.state?.session]);
  const session_column = [
    {
      name: "",
      selector: (row) => row.input,
      wrap: true,
      width: `${billingForm.isNew ? "150px" : 0}`,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      wrap: true,
    },
    {
      name: "Staff",
      selector: (row) => row.staff,
      wrap: true,
    },
    {
      name: "Session Time In",
      selector: (row) => row.startTime,
      wrap: true,
    },
    {
      name: "Session Time Out",
      selector: (row) => row.endTime,
      wrap: true,
    },
  ];
  const payment_column = [
    {
      name: "",
      selector: (row) => row.input,
      wrap: true,
      width: `${billingForm.isNew ? "150px" : 0}`,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      wrap: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
      wrap: true,
    },
    {
      name: "Payed",
      selector: (row) => row.currentPaidAmount,
      wrap: true,
    },
    {
      name: "Remaining",
      selector: (row) => row.remaining,
      wrap: true,
    },
  ];
  const session_data = billingForm.isNew
    ? patientSession
        ?.filter((o) => o.status !== "Completed")
        ?.map((e, index) => {
          return {
            index: index,
            date: e.date,
            startTime: getFormatedTime(e.startTime),
            endTime: getFormatedTime(e.endTime),
            staff: (
              <>
                {e?.staff?.map((o) => {
                  return <div className="">{o.name}</div>;
                })}
              </>
            ),
            input: (
              <>
                {billingForm.isNew ? (
                  <>
                    <input
                      type="checkbox"
                      checked={tempSelectedSession.id == e.id}
                      onClick={() => {
                        setTempSession(e);
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ),
          };
        })
    : [
        {
          index: 0,
          date: new Date(selectedSession?.all_data?.date).toLocaleDateString(),
          startTime: getFormatedTime(selectedSession?.all_data?.startTime),
          endTime: getFormatedTime(selectedSession?.all_data?.endTime),
          staff: (
            <>
              {selectedSession?.all_data?.staff?.map((o) => {
                return <div className="">{o.username}</div>;
              })}
            </>
          ),
        },
      ];
  const others_column = [
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Price",
      selector: (row) => `$${row.price}`,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "200px",
    },
  ];

  const adjustment_column = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Discount",
      selector: (row) =>
        row.discountType === "Percentage"
          ? `${row.discount}%`
          : `$${row.discount}`,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "200px",
    },
  ];

  const adjustment_data = paymentAdjustments.map((e, index) => {
    return {
      index,
      name: e.name,
      discountType: e.discountType,
      discount: e.discount,
      action: (
        <div className="d-flex ">
          <div
            className="cursor-pointer"
            onClick={() => {
              onExtraFormChange("adjustment_name", e.name);
              onExtraFormChange("adjustment_discountType", e.discountType);
              onExtraFormChange("adjustment_discount", e.discount);
              setItemToEdit(index);
              setModal("adjustment");
            }}
          >
            <i className="fa fa-pen"></i>
          </div>
          <div
            className="ms-3  cursor-pointer icon-width"
            onClick={() => {
              setItemToDel(index);
              setOpenModal(true);
              setIsDelAdjustment(true);
            }}
          >
            <i className="fa fa-trash"></i>
          </div>
        </div>
      ),
    };
  });

  const others_data = sessionInvoices
    ?.map((e, index) => {
      return {
        index,
        description: e.description,
        price: e.total_price?.toLocaleString(),
        type: e.type,
        isExtra: e.isExtra,
        action: (
          <div className="d-flex ">
            <div
              className="cursor-pointer"
              onClick={() => {
                onExtraFormChange("others_description", e.description);
                onExtraFormChange("others_price", e.total_price);
                setItemToEdit(index);
                setModal("other");
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="ms-3  cursor-pointer icon-width"
              onClick={() => {
                setItemToDel(index);
                setOpenModal(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        ),
      };
    })
    ?.filter((e) => e.type == "OTHER");
  const package_column = [
    {
      name: "Type",
      selector: (row) => row.type,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Unit Price",
      selector: (row) => row.price,
      wrap: true,
    },
    {
      name: "Limit",
      selector: (row) => row.limit,
      wrap: true,
    },
    {
      name: "Used",
      selector: (row) => row.used,
      wrap: true,
    },
    {
      name: "Discount",
      selector: (row) => row.discount,
      wrap: true,
    },
    {
      name: "Subtotal",
      selector: (row) => row.total_price,
      wrap: true,
    },
  ];

  const products_column = [
    {
      name: "Item Description",
      selector: (row) => row.name,
      wrap: true,
    },
    // {
    // 	name: "Type",
    // 	selector: (row) => row.typeDrug === "external" ? "External" : "Internal",
    // 	wrap: true,
    // },
    // {
    // 	name: "Unit Price",
    // 	selector: (row) => row.price,
    // 	wrap: true,
    // },
    {
      name: "Quantity",
      selector: (row) => row.qty,
      wrap: true,
    },
    {
      name: "Promo",
      selector: (row) =>
        row.promoDiscountType === "Percentage"
          ? `${row.promoDiscountValue}%`
          : `$${row.promoDiscountValue ?? 0}`,
      wrap: true,
    },
    {
      name: "Discount",
      selector: (row) =>
        row.discountType === "Percentage"
          ? `${row.discount}%`
          : `$${row.discount}`,
      wrap: true,
    },
    {
      name: "Amount",
      selector: (row) => `$${row.total_price}`,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      wrap: true,
      width: "200px",
    },
  ];

  const products_data = sessionInvoices
    .map((o, i) => {
      return {
        name: o.name,
        price: o.price,
        cost: o.cost,
        type: o.type,
        qty: o.qty,
        discount: o.discount || 0,
        discountType: o.discountType,
        total_price: o.total_price?.toLocaleString(),
        promoDiscountType: o.promoDiscountType,
        promoDiscountValue: o.promoDiscountValue,
        promoName: o.promoName,
        isAlreadyPaid: o.isAlreadyPaid,
        typeDrug: o.typeDrug,
        action: (
          <div className="d-flex ">
            {o.typeDrug !== "external" ? (
              <React.Fragment>
                {!o.isAlreadyPaid ? (
                  <>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setItemToEdit(i);
                        setNewQty(o.qty);
                        setNewRemark(o.remark);
                        setNewDisc(o.discount);
                        setNewDiscType(o.discountType);
                        setItemToEditPrice(o.price);
                        setTypeEdit("product");
                        setModal("edit");
                      }}
                    >
                      <i className="fa fa-pen"></i>
                    </div>
                  </>
                ) : (
                  <div className="me-3"></div>
                )}
                <div
                  className="ms-3  cursor-pointer icon-width"
                  onClick={() => {
                    setItemToDel(i);
                    setOpenModal(true);
                  }}
                >
                  <i className="fa fa-trash"></i>
                </div>
                <div
                  className="cursor-pointer icon-width"
                  onClick={() => {
                    setmodalLabelPDF(true);
                    let product = options?.medications.find(
                      (a) => a?.id == o?.id
                    );
                    let detail =
                      location?.state?.session?.patientPrescriptionSheet?.find(
                        (a) => a?.drugId == o.id
                      );
                    setselectedLabel({
                      drugName: o?.name ?? "",
                      date: moment(new Date()).format("DD MMM YYYY") ?? "",
                      quantity: `${detail?.totalDosage || ""} ${
                        detail?.code || ""
                      }`,
                      description:
                        `${detail?.instruction || ""} ${
                          detail?.totalDosage || ""
                        } ${detail?.dosage || ""} ${
                          detail?.periodDescription || ""
                        } ${
                          detail?.takePeriod && detail?.takeType
                            ? `For ${detail?.takePeriod} ${detail?.takeType}`
                            : ""
                        }`.trim("") ?? "",
                      expDate: product?.expDate
                        ? moment(product?.expDate).format("DD MMM YYYY")
                        : "",
                      batch: product?.batchNumber ?? "",
                      paitentName:
                        location?.state?.session?.appointment?.patient
                          ?.patientProfile?.fullName ?? "",
                      paitentId:
                        location?.state?.session?.appointment?.patient
                          ?.accountNumber ?? "",
                    });
                  }}
                >
                  <i className="fa fa-print"></i>
                </div>
              </React.Fragment>
            ) : (
              <div className="ms-3 cursor-pointer icon-width"></div>
            )}
          </div>
        ),
      };
    })
    .filter((o) => o.type == "PRODUCT");
  const treatments_data = sessionInvoices
    .map((o, i) => {
      return {
        name: o.name,
        price: o.price,
        type: o.type,
        qty: o.qty,
        discountType: o.discountType,
        discount: o.discount || 0,
        total_price: o.total_price?.toLocaleString(),
        isAlreadyPaid: o.isAlreadyPaid,
        promoDiscountType: o.promoDiscountType,
        promoDiscountValue: o.promoDiscountValue,
        promoName: o.promoName,
        action: (
          <div className="d-flex ">
            {!o.isAlreadyPaid ? (
              <>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setItemToEdit(i);
                    setNewQty(o.qty);
                    setNewRemark(o.remark);
                    setNewDisc(o.discount);
                    setNewDiscType(o.discountType);
                    setItemToEditPrice(o.price);
                    setTypeEdit("treatment");
                    setModal("edit");
                  }}
                >
                  <i className="fa fa-pen"></i>
                </div>
              </>
            ) : (
              <div className="me-3"> </div>
            )}
            <div
              className="ms-3  cursor-pointer icon-width"
              onClick={() => {
                setItemToDel(i);
                setOpenModal(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        ),
      };
    })
    .filter((o) => o.type == "SERVICE");
  const newPackage = options?.packages?.find(
    (o) => o.id == extraStates.package_id
  );
  const previousPackage = options?.previousPackages?.find(
    (o) =>
      o.id == extraStates.previous_package_id &&
      o.billingId == extraStates.previous_billing_id
  );
  const newPackProducts =
    newPackage?.products?.map((o, i) => {
      return {
        index: i,
        name: o.name,
        type: "Product",
        price: o.unit_price,
        limit: o.qty,
        used: (
          <>
            <div className="d-flex">
              <div
                className="pointer"
                onClick={() => {
                  if (o.used == 0) return;
                  newPackage.products[i].used = o.used - 1;
                  const updatedPackages = options.packages?.map((p) => {
                    if (p.id == newPackage.id) {
                      return newPackage;
                    }
                    return p;
                  });
                  setOptions((val) => {
                    return {
                      ...val,
                      packages: updatedPackages,
                    };
                  });
                }}
              >
                <i
                  class="fa fa-minus-square"
                  style={{ color: "#34556F" }}
                  aria-hidden="true"
                ></i>
              </div>
              <span className="mx-2">{o.used}</span>
              <div
                className="pointer"
                onClick={() => {
                  if (o.used == o.qty) return;
                  newPackage.products[i].used = o.used + 1;
                  const updatedPackages = options.packages?.map((p) => {
                    if (p.id == newPackage.id) {
                      return newPackage;
                    }
                    return p;
                  });
                  setOptions((val) => {
                    return {
                      ...val,
                      packages: updatedPackages,
                    };
                  });
                }}
              >
                <i
                  class="fa fa-plus-square"
                  style={{ color: "#34556F" }}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </>
        ),
        discount: `${o.discount ? o.discount : 0}%`,
        total_price: o.total_price,
      };
    }) ?? [];
  const previousPackProducts =
    previousPackage?.products?.map((o, i) => {
      return {
        index: i,
        name: o.name,
        type: "Product",
        price: o.unit_price?.toLocaleString(),
        limit: o.qty,
        isAlreadyPaid: true,
        used: (
          <>
            <div className="d-flex">
              <div
                className="pointer"
                onClick={() => {
                  if (o.used == 0) return;
                  previousPackage.products[i].used = o.used - 1;
                  const updatedPackages = options.previousPackages?.map((p) => {
                    if (p.id == previousPackage.id) {
                      return previousPackage;
                    }
                    return p;
                  });
                  setOptions((val) => {
                    return {
                      ...val,
                      previousPackages: updatedPackages,
                    };
                  });
                }}
              >
                <i
                  class="fa fa-minus-square"
                  style={{ color: "#34556F" }}
                  aria-hidden="true"
                ></i>
              </div>
              <span className="mx-2">{o.used}</span>
              <div
                className="pointer"
                onClick={() => {
                  if (o.used == o.qty) return;
                  previousPackage.products[i].used = o.used + 1;
                  const updatedPackages = options.previousPackages?.map((p) => {
                    if (p.id == previousPackage.id) {
                      return previousPackage;
                    }
                    return p;
                  });
                  setOptions((val) => {
                    return {
                      ...val,
                      previousPackages: updatedPackages,
                    };
                  });
                }}
              >
                <i
                  class="fa fa-plus-square"
                  style={{ color: "#34556F" }}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </>
        ),
        discount: `${o.discount ? o.discount : 0}%`,
        total_price: o.total_price,
      };
    }) ?? [];
  const newPackTreatments =
    newPackage?.treatments?.map((o, i) => {
      return {
        index: i,
        name: o.name,
        type: "Treatment",
        price: o.unit_price?.toLocaleString(),
        limit: o.qty,
        used: (
          <>
            <div className="d-flex">
              <div
                className="pointer"
                onClick={() => {
                  if (o.used == 0) return;
                  newPackage.treatments[i].used = o.used - 1;
                  const updatedPackages = options.packages?.map((p) => {
                    if (p.id == newPackage.id) {
                      return newPackage;
                    }
                    return p;
                  });
                  setOptions((val) => {
                    return {
                      ...val,
                      packages: updatedPackages,
                    };
                  });
                }}
              >
                <i
                  class="fa fa-minus-square"
                  style={{ color: "#34556F" }}
                  aria-hidden="true"
                ></i>
              </div>
              <span className="mx-2">{o.used}</span>
              <div
                className="pointer"
                onClick={() => {
                  if (o.used == o.qty) return;
                  newPackage.treatments[i].used = o.used + 1;
                  const updatedPackages = options.packages?.map((p) => {
                    if (p.id == newPackage.id) {
                      return newPackage;
                    }
                    return p;
                  });
                  setOptions((val) => {
                    return {
                      ...val,
                      packages: updatedPackages,
                    };
                  });
                }}
              >
                <i
                  class="fa fa-plus-square"
                  style={{ color: "#34556F" }}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </>
        ),
        discount: `${o.discount ? o.discount : 0}%`,
        total_price: o.total_price,
      };
    }) ?? [];
  const previousPackTreatments =
    previousPackage?.treatments?.map((o, i) => {
      return {
        index: i,
        name: o.name,
        type: "Treatment",
        isAlreadyPaid: true,
        price: o.unit_price,
        limit: o.qty,
        used: (
          <>
            <div className="d-flex">
              <div
                className="pointer"
                onClick={() => {
                  if (o.used == 0) return;
                  previousPackage.treatments[i].used = o.used - 1;
                  const updatedPackages = options.previousPackages?.map((p) => {
                    if (p.id == previousPackage.id) {
                      return previousPackage;
                    }
                    return p;
                  });
                  setOptions((val) => {
                    return {
                      ...val,
                      previousPackages: updatedPackages,
                    };
                  });
                }}
              >
                <i
                  class="fa fa-minus-square"
                  style={{ color: "#34556F" }}
                  aria-hidden="true"
                ></i>
              </div>
              <span className="mx-2">{o.used}</span>
              <div
                className="pointer"
                onClick={() => {
                  if (o.used == o.qty) return;
                  previousPackage.treatments[i].used = o.used + 1;
                  const updatedPackages = options.previousPackages?.map((p) => {
                    if (p.id == previousPackage.id) {
                      return previousPackage;
                    }
                    return p;
                  });
                  setOptions((val) => {
                    return {
                      ...val,
                      previousPackages: updatedPackages,
                    };
                  });
                }}
              >
                <i
                  class="fa fa-plus-square"
                  style={{ color: "#34556F" }}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </>
        ),
        discount: `${o.discount ? o.discount : 0}%`,
        total_price: o.total_price,
      };
    }) ?? [];
  const package_data = [
    ...newPackProducts,
    // ...previousPackProducts,
    ...newPackTreatments,
    // ...previousPackTreatments,
  ];
  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
        paddingTop: "4px",
        paddingBottom: "4px",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const conditionalPaidRowStyles = [
    {
      when: (row) => row.typeDrug === "external",
      style: {
        backgroundColor: "#ddd",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.isAlreadyPaid,
      style: {
        backgroundColor: "#B6ee7a",
        borderWidth: "0px",
      },
    },
  ];

  useMemo(() => {
    if (pointsExpiry) {
      setExpiryDate(
        new Date(new Date().setMonth(new Date().getMonth() + pointsExpiry))
      );
    }
  }, [pointsExpiry]);

  useEffect(() => {
    if (fileInvoice !== null) {
      sendInvoice();
    }
  }, [fileInvoice]);

  useEffect(() => {
    if (options.branches?.length > 0) {
      if (billingForm.branch === "" && billingForm.patient === "") {
        const selectedBranch = options.branches[0];
        onFormChange("branch", selectedBranch.value);
        if (selectedBranch?.locations?.length > 0) {
          onFormChange("location", selectedBranch?.locations[0]?._id);
        }
      }
    }
  }, [options.branches]);

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  };

  const resetPayment = () => {
    setSessionInvoices([]);
    setTax([]);
    setUsePoint(0);
    setRemarkDiscount("");
    setStatus(false);
    setPaymentMethod(null);
    setPaymentMethodName("");
    setPaymentAdjustments([]);
    setPromo(0);
    // dispatch(resetValue("billingForm"));

    formDataChangedRef.current = null;
    dispatch(
      setFormData({
        ...formData,
        [pathname]: null,
      })
    );
    navigate("/billing");
  };

  const getPaymentAfterSubmit = async (newPayment, haveInvoice) => {
    const { result, error } = await authorizedRequests({
      url: "payment/getPaymentByBillingId",
      method: "post",
      data: {
        _id: newPayment?.data?._id,
      },
    });

    if (result?.data) {
      if (haveInvoice) {
        setInvoicePayment(result?.data);
      } else {
        resetPayment();
      }
    } else {
      resetPayment();
    }
  };

  const sendInvoice = async () => {
    const formData = new FormData();
    formData.append(
      "fullname",
      invoicePayment?.patient?.patientProfile?.fullName
    );
    formData.append("email", invoicePayment?.patient?.patientProfile?.email);
    formData.append("invoiceNo", getInvoiceId(invoicePayment?.invoiceNo));

    const readerClient = await readFileAsync(fileInvoice);
    const formFileClient = new File([readerClient], `Invoice.pdf`, {
      lastModified: Date.now(),
      type: "application/pdf",
    });
    formData.append("fileInvoice", formFileClient);

    const { result, error } = await authorizedRequests({
      url: "payment/sendInvoice",
      method: "post",
      data: formData,
    });

    setFileInvoice(null);
    setInvoicePayment(null);
    resetPayment();
  };

  const getProductName = () => {
    if (!selectedMedicationCategory) {
      return "Product";
    }

    const productCategory = options?.medicationCategories?.find(
      (m) => m._id === selectedMedicationCategory
    );
    if (!productCategory) {
      return "Product";
    }

    return productCategory.name;
  };

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: location?.state?.dataId,
      type: "Invoice",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  return (
    <>
      <div className="container">
        <div className="mainHeading px-0">
          <div>
            <h1>
              <i
                className="fa fa-arrow-left cursor-pointer"
                onClick={() => {
                  navigate(-1);
                }}
              ></i>
              <span className="400-28-32">
                {location?.state?.formStatus} List Invoice
              </span>
            </h1>
            {location?.state?.formStatus && editLogs && (
              <div style={{ marginLeft: "5px" }}>
                {" "}
                By {editLogs?.userName} At{" "}
                {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
              </div>
            )}
          </div>
        </div>
        <div style={{ background: "white" }} className="p-3">
          <form>
            <div className="d-flex">
              <div className="col-md-6" style={{ paddingRight: "20px" }}>
                <Form.Group>
                  <Form.Label className="black-label-text required">
                    Patient
                  </Form.Label>

                  <AsyncSelect
                    value={billingForm.patient}
                    setValue={(value) => {
                      setSelectedOptPatient(value);
                      setIsMember(value?.isMember);
                      setSelectedPatient(value?._id);
                      const gstTax = options.tax.find(
                        (o) => o?.name?.toLowerCase() === "gst"
                      )?.id;
                      if (gstTax) setTax([gstTax]);
                      else setTax([]);

                      onFormChange("patient", value?._id);
                      onFormChange(
                        "recommended",
                        value?.patientProfile?.recommendFrom
                      );
                      setPromo(0);
                      setUsePoint(0);
                      setSubTotal(0);
                      setTotal(0);
                      setRewardPoints(0);
                      setSessionInvoices([]);
                    }}
                    loadOptions={(search, loadedOptions, { page }) =>
                      loadOptionPatients(search, loadedOptions, { page })
                    }
                    loadSelectedOption={loadSelectedPatient}
                  />
                  {/* <SelectComponent
										singleSelect
										searchEnabled
										// isNullEnabled
										selectedValue={billingForm.patient}
										options={options.allPatients}
										onChange={(e) => {
											setIsMember(
												options?.allPatients?.find(
													(o) => o?.value == e?.value,
												)?.isMember,
											);
											setSelectedPatient(options?.allPatients?.find(
												(o) => o?.value == e?.value
											)?.id)
											const gstTax = options.tax.find(
												(o) =>
													o?.name?.toLowerCase() ==
													"gst",
											)?.id;
											if (gstTax) setTax([gstTax]);
											else setTax([]);

											onFormChange("patient", e.value);
											onFormChange("recommended", e.kol);

											setPromo(0);
											setUsePoint(0);
											setSubTotal(0);
											setTotal(0);
											setRewardPoints(0);
											setSessionInvoices([]);
										}}
										name={"patient"}
									/> */}
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label className="black-label-text required">
                    Branch
                  </Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={billingForm.branch}
                    options={options.branches}
                    onChange={(e) => {
                      onFormChange("branch", e.value);
                      onFormChange("location", null);
                      // onFormChange("patient", null);
                    }}
                    name={"branch"}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black-label-text required">
                    Location
                  </Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    // isNullEnabled
                    selectedValue={billingForm.location}
                    options={options.locations}
                    onChange={(e) => {
                      onFormChange("location", e.value);
                      // onFormChange("patient", null);
                    }}
                    name={"location"}
                  />
                </Form.Group>
                <Form.Group className=" mt-3 col-12">
                  <Form.Label className="black-label-text">
                    Program Expiration
                  </Form.Label>
                  <SelectComponent
                    searchEnabled
                    isNullEnabled
                    array={programExpiration}
                    options={options.programs}
                    placeholder="Write..."
                    onChange={(e) => {
                      setProgramExpiration(e);
                    }}
                    name={"programExpiration"}
                  />
                </Form.Group>

                {/* <Form.Group className="mt-3">
									<Form.Label className="black-label-text">
										Status *
									</Form.Label>
									<SelectComponent
										singleSelect
										searchEnabled
										options={options.status}
										selectedValue={status}
										onChange={(e) => {
											setStatus(e.value);
										}}
										placeholder="Write..."
										name={"status"}
									/>
								</Form.Group> */}
              </div>

              <div className="col-md-6" style={{ paddingLeft: "20px" }}>
                <p className="black-label-text mb-3 mt-3">Category Cost</p>
                <div className="d-flex flex-wrap mb-3">
                  {options.categoryCost?.length > 0 &&
                    options.categoryCost?.map((o) => {
                      return (
                        <>
                          <input
                            type={"checkbox"}
                            checked={o.isChecked}
                            onClick={() => {
                              var tempArr = options.categoryCost;
                              tempArr = tempArr.map((k) => {
                                if (k.id == o.id) {
                                  return {
                                    ...k,
                                    isChecked: !k.isChecked,
                                  };
                                }
                                return k;
                              });
                              setOptions((val) => {
                                return {
                                  ...val,
                                  categoryCost: tempArr,
                                };
                              });
                              dispatch(
                                setForm({
                                  parent: "billingForm",
                                  child: "categoryCost",
                                  value: tempArr,
                                })
                              );
                            }}
                          />
                          <span className="me-3 ms-2">{o.name}</span>
                        </>
                      );
                    })}
                </div>
                <Form.Group className="">
                  <Form.Label className="black-label-text">
                    Recommended{" "}
                    <span className="font-400-20">(Patient/KOL/Channel)</span>
                  </Form.Label>
                  <AsyncSelect
                    value={billingForm.recommended}
                    setValue={(value) => {
                      onFormChange("recommended", value._id);
                    }}
                    loadOptions={(search, loadedOptions, { page }) =>
                      loadOptionPatients(search, loadedOptions, { page })
                    }
                    loadSelectedOption={loadSelectedPatient}
                  />
                  {/* <SelectComponent
										searchEnabled
										singleSelect
										// isNullEnabled
										// nullNotHidden
										selectedValue={billingForm.recommended}
										options={options.allPatients}
										placeholder="Write..."
										onChange={(e) => {
											onFormChange(
												"recommended",
												e.value,
											);
										}}
										name={"recommended"}
									/> */}
                </Form.Group>
                <Form.Group className=" mt-3">
                  <Form.Label className="black-label-text">
                    Campaign{" "}
                  </Form.Label>
                  <SelectComponent
                    searchEnabled
                    singleSelect
                    // isNullEnabled
                    // nullNotHidden
                    selectedValue={billingForm.campaign}
                    options={options.campaigns}
                    placeholder="Write..."
                    onChange={(e) => {
                      onFormChange("campaign", e.value);
                    }}
                    name={"campaign"}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label className="black-label-text">
                    Campaign Remarks
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      name={"campaignRemarks"}
                      as="textarea"
                      rows={3}
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      onChange={(e) => {
                        onFormChange("campaignRemarks", e.target.value);
                      }}
                      value={billingForm.campaignRemarks}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>

            <div className="highlighted-container mt-2 mb-4">
              <div className="d-flex">
                <div className="col-md-8">
                  <div
                    className="p-4"
                    style={{
                      paddingRight: "20px",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    <Row className="ps-2">
                      {options?.medicationCategories?.length > 0 &&
                        options?.medicationCategories?.map((med) => {
                          return (
                            <>
                              <Button
                                className="form-button m-2"
                                style={{
                                  minWidth: 150,
                                  wordBreak: "break-all",
                                }}
                                onClick={() => {
                                  setSelectedMedicationCategory(med._id);
                                  setModal("medication");
                                }}
                              >
                                + Add {med.name}
                              </Button>
                            </>
                          );
                        })}

                      <Button
                        className="form-button m-2"
                        onClick={() => {
                          setModal("package");
                        }}
                      >
                        +Add Package
                      </Button>
                      <Button
                        className="form-button m-2"
                        onClick={() => {
                          setModal("treatment");
                        }}
                      >
                        +Add Treatment
                      </Button>
                      <Button
                        className="form-button m-2"
                        onClick={() => {
                          setItemToEdit();
                          setModal("other");
                        }}
                      >
                        +Add Other
                      </Button>
                      <Button
                        className="form-button m-2"
                        onClick={() => {
                          setModal("adjustment");
                        }}
                      >
                        +Add Adjustment
                      </Button>
                    </Row>

                    <div>
                      <p
                        className="font-600-18 grey mt-3"
                        style={{ display: "inline-block" }}
                      >
                        {options?.medicationCategories
                          ?.map((medCat) => medCat.name)
                          .join(" | ")}
                      </p>

                      {products_data?.filter((x) => x.typeDrug === "external")
                        ?.length > 0 && (
                        <Button
                          className="form-button m-2"
                          style={{ display: "inline-block", float: "right" }}
                          onClick={() => {
                            setModalExternal(true);
                          }}
                        >
                          <i
                            class="fa fa-print print-color"
                            style={{ color: "white" }}
                          ></i>
                        </Button>
                      )}
                    </div>
                    <DataTable
                      data={products_data}
                      columns={products_column}
                      customStyles={customStyles}
                      conditionalRowStyles={conditionalPaidRowStyles}
                    />

                    <p className="font-600-18 grey mt-3">Packages</p>
                    <PackagesTable
                      sessionInvoices={sessionInvoices}
                      setPromo={setPromo}
                      setSubTotal={setSubTotal}
                      setTotal={setTotal}
                      setTax={setTax}
                      setUsePoint={setUsePoint}
                      onRowEdit={(i, qty, disc, price, remark) => {
                        setItemToEdit(i);
                        setNewQty(qty);
                        setNewRemark(remark);
                        setNewDisc(disc);
                        setItemToEditPrice(price);
                        setTypeEdit("package");
                        setModal("edit");
                      }}
                      setSessionInvoices={setSessionInvoices}
                    />

                    <p className="font-600-18 grey mt-4">Treatments</p>
                    <DataTable
                      data={treatments_data}
                      columns={products_column}
                      customStyles={customStyles}
                      conditionalRowStyles={conditionalPaidRowStyles}
                    />
                    <p className="font-600-18 grey mt-3">Others</p>
                    <DataTable
                      data={others_data}
                      columns={others_column}
                      customStyles={customStyles}
                      conditionalRowStyles={conditionalPaidRowStyles}
                    />
                    <p className="font-600-18 grey mt-3">Adjustment</p>
                    <DataTable
                      data={adjustment_data}
                      columns={adjustment_column}
                      customStyles={customStyles}
                      conditionalRowStyles={conditionalPaidRowStyles}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="p-4">
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className="font-500-20 py-1">
                        <b>Available Points</b>
                      </div>
                      {billingForm?.patient && (
                        <>
                          <div className="align-self-center ms-auto font-500-20">
                            {selectedOptPatient?.points ?? 0}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="highlighted-container d-flex px-3 p-2 col-12">
                      <div className="font-500-20 py-1">
                        <b>Sub Total Amount</b>
                      </div>
                      <>
                        <div
                          className="align-self-center ms-auto font-500-20"
                          style={{ fontWeight: "600" }}
                        >
                          ${numberFormatter.format(subTotal) || 0}
                        </div>
                      </>
                    </div>
                    <div className="col-12">
                      <Form.Group className="mt-3 ">
                        <Form.Label className="black-label-text required">
                          Discount Type
                        </Form.Label>
                        <SelectComponent
                          singleSelect
                          searchEnabled
                          // defaultValue={selectedOption}
                          selectedValue={selectedOption?.value}
                          options={options.discountType}
                          onChange={setSelectedOption}
                        />
                      </Form.Group>
                    </div>
                    <Form.Group className="mt-3 ">
                      <Form.Label className="black-label-text">
                        Promo Discount
                      </Form.Label>
                      <div className="d-flex">
                        <div className="col-12">
                          <InputGroup>
                            <Form.Control
                              name={"promo"}
                              type={"number"}
                              className="hideArrows"
                              placeholder="Write..."
                              onChange={(e) => {
                                if (selectedOption?.value === "Percentage") {
                                  setPromo(e.target.value);
                                }

                                if (selectedOption?.value === "Amount") {
                                  setPromo(e.target.value);
                                }
                              }}
                              value={promo}
                            />
                          </InputGroup>
                        </div>{" "}
                      </div>
                    </Form.Group>
                    <Form.Group className="mt-3 col-12">
                      <Form.Label className="black-label-text">
                        Use Point
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          className="hideArrows"
                          name={"usePoint"}
                          placeholder="Write..."
                          onChange={(e) => {
                            // if (
                            //   e.target.value <= selectedOptPatient?.points ??
                            //   0
                            //   &&
                            //   total -
                            // 	 e.target.value *
                            // 	   setting?.value >
                            // 	 0
                            // ) {
                            setUsePoint(e.target.value);
                            // } else {
                            //   toastify(
                            //     "Patient doesn't have any point",
                            //     "error"
                            //   );
                            // }
                          }}
                          value={usePoint}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mt-3 ">
                      <Form.Label className="black-label-text">
                        Remark
                      </Form.Label>
                      <div className="d-flex">
                        <div className="col-12">
                          <InputGroup>
                            <Form.Control
                              name={"remark"}
                              as="textarea"
                              rows={2}
                              placeholder="Write..."
                              style={{ resize: "none" }}
                              onChange={(e) => {
                                setRemarkDiscount(e.target.value);
                              }}
                              value={remarkDiscount}
                            />
                          </InputGroup>
                        </div>{" "}
                      </div>
                    </Form.Group>
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mt-3 mb-2">
                      <span className="font-500-20 py-1">
                        <b>Discount Value</b>
                      </span>
                      <span className="ms-auto font-500-20">
                        {selectedOption.value === "Percentage"
                          ? `$${numberFormatter.format(
                              (promo / 100) * subTotal
                            )}`
                          : `$${numberFormatter.format(promo)}`}
                      </span>
                    </div>
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <span className="font-500-20 py-1">
                        <b>Use Point Amount</b>
                      </span>
                      <span className="ms-auto font-500-20">
                        {usePoint} = $
                        {numberFormatter.format(
                          usePoint *
                            ((setting?.conversion || 0) / (setting?.value || 0))
                        ) || 0}
                      </span>
                    </div>
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className=" font-500-20 py-1">
                        <b>Total Amount</b>
                      </div>
                      <div
                        className="align-self-center ms-auto font-500-20"
                        style={{ fontWeight: "600" }}
                      >
                        ${numberFormatter.format(total) || 0}
                      </div>
                    </div>
                    <div className="highlighted-container d-flex px-3 p-2 col-12 mb-2">
                      <div className=" font-500-20 py-1">
                        <b>Amount After GST</b>
                      </div>
                      <div
                        className="align-self-center ms-auto font-500-20"
                        style={{ fontWeight: "600" }}
                      >
                        ${numberFormatter.format(gst) || 0}
                      </div>
                    </div>

                    <div style={{ marginTop: "15px" }}>
                      <input
                        type={"checkbox"}
                        checked={isFree}
                        onClick={() => {
                          setIsFree((val) => {
                            return !val;
                          });
                        }}
                      />
                      <span
                        className="me-3 ms-2"
                        style={{ fontWeight: "bold" }}
                      >
                        Is Free for KOL
                      </span>
                    </div>

                    <div style={{ marginTop: "15px" }}>
                      <input
                        type={"checkbox"}
                        checked={isFreeChannel}
                        onClick={() => {
                          setIsFreeChannel((val) => {
                            return !val;
                          });
                        }}
                      />
                      <span
                        className="me-3 ms-2"
                        style={{ fontWeight: "bold" }}
                      >
                        Is Free for Media
                      </span>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <Button
                        className="addItem mt-4"
                        onClick={() => {
                          setNeedInvoice(false);
                          if (usePoint > selectedOptPatient?.points ?? 0) {
                            toastify("Patient doesn't have any point", "error");
                            return;
                          }
                          if (
                            // status &&
                            isMember &&
                            (location?.state?.rewardPoints == null ||
                              location?.state?.rewardPoints == undefined)
                          ) {
                            setModal("points");
                          } else {
                            submitForm(false);
                          }
                        }}
                        style={{
                          width: "200px",
                          height: "50px",
                          display: "inline-block",
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        className="addItem mt-4"
                        onClick={() => {
                          setNeedInvoice(true);
                          if (
                            // status &&
                            isMember &&
                            (location?.state?.rewardPoints == null ||
                              location?.state?.rewardPoints == undefined)
                          ) {
                            setModal("points");
                          } else {
                            submitForm(true);
                          }
                        }}
                        style={{
                          width: "200px",
                          height: "50px",
                          display: "inline-block",
                          marginLeft: "20px",
                        }}
                      >
                        Finalize & Invoicing
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <Modal
          centered
          show={openModal}
          onHide={() => {
            setItemToDel(null);

            setOpenModal(!openModal);
          }}
        >
          <Modal.Body className="font-18">
            Confirm Item Deletion
            <div className="align-self-end m-3 d-flex">
              <Button
                className="me-3 delete-no ms-auto"
                onClick={() => {
                  setOpenModal(false);
                  setIsDelAdjustment(false);
                }}
              >
                No
              </Button>
              <Button
                className="delete-yes"
                onClick={() => {
                  if (!isDelAdjustment) {
                    if (sessionInvoices?.length == 1) {
                      setSubTotal(0);
                      setPromo(0);
                      setTotal(0);
                      // setTax([]);
                      setUsePoint(0);
                    }
                    let tempArr = [...sessionInvoices];
                    tempArr = tempArr.filter((k, idx) => idx !== itemToDel);
                    setSessionInvoices([...tempArr]);
                  } else {
                    let tempArr = [...paymentAdjustments];
                    tempArr = tempArr.filter((k, idx) => idx !== itemToDel);
                    setPaymentAdjustments([...tempArr]);
                  }
                  setOpenModal(false);
                  setIsDelAdjustment(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          size={"xl"}
          show={showModal.medication}
          onHide={() => {
            setModal("medication");
          }}
        >
          <Modal.Header>
            <div className="d-flex justify-content-between w-100">
              <div>
                <span className="grey font-600-24 ">{getProductName()}</span>
              </div>
              <i
                className="fa fa-xmark align-self-center pointer"
                onClick={() => {
                  setModal("medication");
                }}
                style={{
                  fontSize: "30px",
                }}
              ></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex w-100">
              <div className="col-6 pe-2">
                <Form.Group>
                  <Form.Label className="black-label-text">
                    {getProductName()}
                  </Form.Label>
                  <SelectComponent
                    searchEnabled
                    singleSelect
                    // isNullEnabled
                    // nullNotHidden
                    selectedValue={extraStates?.product_id}
                    options={
                      options.medications.filter(
                        (o) => o.typeId == selectedMedicationCategory
                      ) ?? []
                    }
                    placeholder="Write..."
                    onChange={(e) => {
                      const isExists = sessionInvoices?.find(
                        (o) => o.id == e.value
                      );
                      if (isExists) {
                        toastify("Item already exists in invoices", "error");
                        return;
                      }
                      onExtraFormChange("product_id", e.value);
                      if (e.value) {
                        const unit_price = options?.medications?.find(
                          (o) => o.id == e.value
                        )?.price;
                        onExtraFormChange("product_unit_price", unit_price);
                      }
                    }}
                    name={"product_id"}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black-label-text">Quantity</Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      type="number"
                      className="hideArrows"
                      name={"quantity"}
                      placeholder="Write..."
                      onChange={(e) => {
                        onExtraFormChange("product_quantity", e.target.value);
                      }}
                      value={extraStates.product_quantity}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black-label-text required">
                    Discount Type
                  </Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={extraStates?.product_discountType}
                    options={options.discountType}
                    onChange={(e) =>
                      onExtraFormChange("product_discountType", e.value)
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black-label-text">
                    Discount Value
                  </Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      type="number"
                      className="hideArrows"
                      name={"product_discount"}
                      placeholder="Write..."
                      onChange={(e) => {
                        onExtraFormChange("product_discount", e.target.value);
                      }}
                      value={extraStates.product_discount}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black-label-text">Remark</Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      name="product_remark"
                      as="textarea"
                      rows={2}
                      placeholder="Write..."
                      onChange={(e) => {
                        onExtraFormChange("product_remark", e.target.value);
                      }}
                      value={extraStates.product_remark}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-6 ps-2">
                {extraStates.product_id && (
                  <>
                    <div className="w-100 d-flex justify-content-between grey-div mb-2">
                      <div className="font-500-20">Unit Price </div>
                      <div className="font-400-20">
                        <>
                          {"$"}
                          {
                            options?.medications?.find(
                              (o) => o.id == extraStates?.product_id
                            )?.price
                          }
                        </>
                      </div>
                    </div>
                  </>
                )}
                {extraStates.product_id &&
                  extraStates.product_quantity &&
                  !isNaN(extraStates.product_price) && (
                    <>
                      <div className="w-100 d-flex justify-content-between grey-div">
                        <div className="font-500-20">Total Price </div>
                        <div className="font-400-20">
                          {extraStates?.product_id && (
                            <>
                              {"$"}
                              {extraStates.product_price}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
            <Button
              className="addItem ms-auto mt-3"
              style={{ borderRadius: "25px", width: "150px" }}
              onClick={() => {
                if (
                  !extraStates.product_id ||
                  // !extraStates.product_price ||
                  !extraStates.product_quantity
                ) {
                  setModal("medication");

                  return;
                }

                const selectedProd = options.medications.find(
                  (x) => x.id === extraStates.product_id
                );
                // if (selectedProd) {
                //   if (
                //     selectedProd.qty < parseInt(extraStates.product_quantity)
                //   ) {
                //     toastify("Product quantity not enough", "error");
                //     return;
                //   }
                // }

                let tempArr = [...sessionInvoices];

                tempArr.push({
                  id: extraStates.product_id,
                  name: options.medications?.find(
                    (o) => o.id == extraStates.product_id
                  )?.name,
                  price: extraStates.product_unit_price,
                  cost: selectedProd.cost,
                  qty: extraStates.product_quantity,
                  discountType: extraStates.product_discountType,
                  discount: extraStates.product_discount,
                  remark: extraStates.product_remark,
                  promoDiscountType: selectedProd.promoDiscountType,
                  promoDiscountValue: selectedProd.promoDiscountValue,
                  promoName: selectedProd.promoName,
                  total_price: calcTotalWithDisc(
                    calcTotalWithDisc(
                      extraStates.product_price,
                      selectedProd?.promoDiscountValue,
                      selectedProd?.promoDiscountType
                    ),
                    extraStates.product_discount,
                    extraStates.product_discountType
                  ),
                  type: "PRODUCT",
                  isExtra: true,
                });

                setSessionInvoices(tempArr);

                setExtraStates((val) => {
                  return {
                    ...val,
                    product_remark: "",
                    product_discount: 0,
                    product_discountType: "Amount",
                    product_id: null,
                    product_quantity: null,
                    product_unit_price: null,
                    product_unit_cost: null,
                    product_cost: null,
                    product_price: null,
                  };
                });
                setModal("medication");
                setRecalculateSub(!recalculateSub);
              }}
            >
              Save
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          size={"xl"}
          show={showModal.package}
          onHide={() => {
            onExtraFormChange("package_id", null);
            onExtraFormChange("previous_package_unique_id", null);
            onExtraFormChange("previous_package_id", null);
            onExtraFormChange("previous_billing_id", null);
            setModal("package");
          }}
        >
          <Modal.Header>
            <div className="d-flex justify-content-between w-100">
              <div>
                <span className="grey font-600-24 ">Package</span>
              </div>
              <i
                className="fa fa-xmark align-self-center pointer"
                onClick={() => {
                  setModal("package");
                }}
                style={{
                  fontSize: "30px",
                }}
              ></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="col-md-12">
                  <Form.Label className="black-label-text">
                    New Package
                  </Form.Label>
                  <SelectComponent
                    searchEnabled
                    singleSelect
                    // isNullEnabled
                    // nullNotHidden
                    selectedValue={extraStates?.package_id}
                    options={options.packages}
                    placeholder="Write..."
                    onChange={(e) => {
                      const isExists = sessionInvoices.find(
                        (o) => o.id == e.value
                      );
                      if (isExists) {
                        toastify("Package already exists in invoices", "error");
                        return;
                      }

                      onExtraFormChange("package_id", e.value);
                    }}
                    name={"package_id"}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black-label-text">Quantity</Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      type="number"
                      className="hideArrows"
                      name={"quantity"}
                      placeholder="Write..."
                      onChange={(e) => {
                        onExtraFormChange("package_quantity", e.target.value);
                      }}
                      value={extraStates.package_quantity}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="col-md-12">
                  <Form.Label className="black-label-text required">
                    Discount Type
                  </Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={extraStates?.package_discountType}
                    options={options.discountType}
                    onChange={(e) =>
                      onExtraFormChange("package_discountType", e.value)
                    }
                  />
                </Form.Group>
                <Form.Group className="col-md-12">
                  <Form.Label className="black-label-text">
                    Discount Value
                  </Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      type="number"
                      className="hideArrows"
                      name={"package_discount"}
                      placeholder="Write..."
                      onChange={(e) => {
                        onExtraFormChange("package_discount", e.target.value);
                      }}
                      value={extraStates.package_discount}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black-label-text">Remark</Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      name="package_remark"
                      as="textarea"
                      rows={2}
                      placeholder="Write..."
                      onChange={(e) => {
                        onExtraFormChange("package_remark", e.target.value);
                      }}
                      value={extraStates.package_remark}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              {billingForm.patient && (
                <Form.Group className="col-md-6">
                  <Form.Label className="black-label-text">
                    Current Packages
                  </Form.Label>
                  <SelectComponent
                    searchEnabled
                    singleSelect
                    selectedValue={extraStates?.previous_package_unique_id}
                    options={[...options.previousPackages, ...patientTransfer]}
                    placeholder="Write..."
                    onChange={(e) => {
                      const isExists = sessionInvoices.find(
                        (o) => o.id == e.value
                      );
                      const remainingProducts = e?.products?.find(
                        (o) => o?.used < o?.qty
                      );
                      const remainingTreatments = e?.treatments?.find(
                        (o) => o.used < o.limit
                      );

                      if (isExists) {
                        toastify("Package already exists in invoices", "error");
                        return;
                      }

                      onExtraFormChange("previous_package_unique_id", e.value);
                      onExtraFormChange("previous_package_id", e.packageId);
                      onExtraFormChange("previous_billing_id", e.billingId);
                    }}
                    name={"package_id"}
                  />
                </Form.Group>
              )}
            </div>

            <Button
              className="addItem ms-auto mt-3"
              style={{ borderRadius: "25px", width: "150px" }}
              onClick={() => {
                const tempArr = [...sessionInvoices];
                if (extraStates.package_id) {
                  const packageDetails = options.packages.find(
                    (o) => o.id == extraStates.package_id
                  );

                  packageDetails.treatments?.map((treatment) => {
                    treatment.total_price = treatment.total_price * (extraStates.package_quantity || 1);
                    treatment.ori_total_price = treatment.total_price / (extraStates.package_quantity || 1);
                  });

                  packageDetails.products?.map((product) => {
                    product.total_price = product.total_price * (extraStates.package_quantity || 1);
                    product.ori_total_price = product.total_price / (extraStates.package_quantity || 1);
                  })

                  tempArr.push({
                    id: extraStates.package_id,
                    qty: extraStates.package_quantity || 1,
                    price: packageDetails.price,
                    discount: extraStates.package_discount || 0,
                    discountType: extraStates.package_discountType || 0,
                    remark: extraStates.package_remark,
                    effectiveDate: packageDetails?.effectiveDate,
                    expiredDate: packageDetails?.expiredDate,
                    name: packageDetails.name,
                    treatments: packageDetails.treatments,
                    products: packageDetails.products,
                    promoDiscountType: packageDetails.promoDiscountType,
                    promoDiscountValue: packageDetails.promoDiscountValue,
                    promoName: packageDetails.promoName,
                    total_price: calcTotalWithDisc(
                      calcTotalWithDisc(
                        parseInt(packageDetails.price) *
                          parseInt(extraStates.package_quantity),
                        packageDetails.promoDiscountValue,
                        packageDetails.promoDiscountType
                      ),
                      extraStates.package_discount,
                      extraStates.package_discountType
                    ),
                    type: "PACKAGE",
                    isExtra: true,
                  });
                }

                if (extraStates.previous_package_id) {
                  const previousPackageDetails = [
                    ...options.previousPackages,
                    ...patientTransfer,
                  ].find(
                    (o) => o.value == extraStates?.previous_package_unique_id
                  );

                  tempArr.push({
                    id: extraStates.previous_package_unique_id,
                    // uniqueId:extraStates.previous_package_unique_id,
                    qty: previousPackageDetails?.qty || 1,
                    price: previousPackageDetails?.price,
                    discount: previousPackageDetails.discount || 0,
                    discountType: previousPackageDetails.discountType,
                    name: previousPackageDetails.label,
                    treatments: previousPackageDetails.treatments,
                    products: previousPackageDetails.products,
                    total_price: previousPackageDetails.price,
                    type: "PACKAGE",
                    billingId: previousPackageDetails.billingId,
                    isAlreadyPaid: true,
                    isExtra: true,
                    isLinked: true,
                  });
                }

                setSessionInvoices(tempArr);
                setRecalculateSub(!recalculateSub);

                setExtraStates((val) => {
                  return {
                    ...val,
                    package_discount: 0,
                    package_discountType: "Amount",
                    package_remark: "",
                    package_id: null,
                    package_quantity: null,
                    previous_package_id: null,
                    previous_billing_id: null,
                    previous_package_unique_id: null,
                  };
                });
                setModal("package");
              }}
            >
              Save
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          show={showModal?.points}
          onHide={() => {
            setModal("points");
          }}
        >
          <Modal.Body>
            <div className="text-center w-100 px-5 py-3">
              <span className="font-600-24 theme">
                This billing will get a reward point
              </span>
            </div>
            <div className="w-100 px-5 ">
              <Form.Group className="w-100">
                <Form.Label className="black-label-text">Points</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    className="hideArrows"
                    name={"rewardPoints"}
                    placeholder="Write..."
                    onChange={(e) => {
                      setRewardPoints(e.target.value);
                    }}
                    value={rewardPoints}
                  />
                </InputGroup>
              </Form.Group>
              <div className="d-flex mt-2 justify-content-between">
                {expiryDate && (
                  <>
                    <Form.Group className="w-100">
                      <Form.Label className="black-label-text">
                        Expiry Date
                      </Form.Label>
                      <DateInput
                        value={expiryDate}
                        placeholder="Start Date"
                        onChange={(e) => {
                          setExpiryDate(e);
                        }}
                      />
                    </Form.Group>
                  </>
                )}
              </div>
            </div>
            <div className="d-flex mt-4">
              <Button
                className="white-button"
                style={{ width: "200px", height: "45px" }}
                onClick={() => {
                  setModal("points");
                }}
              >
                Cancel
              </Button>
              <Button
                className="addItem ms-auto"
                style={{ width: "200px", height: "45px" }}
                onClick={() => {
                  setModal("points");
                  submitForm(needInvoice);
                }}
              >
                Save
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          size={"xl"}
          show={showModal.treatment}
          onHide={() => {
            setExtraStates((val) => {
              return {
                ...val,
                remark: "",
                service_discount: 0,
                service_discountType: "Amount",
                service_id: null,
                service_quantity: null,
                service_unit_price: null,
                service_price: null,
              };
            });
            setModal("treatment");
          }}
        >
          <Modal.Header>
            <div className="d-flex justify-content-between w-100">
              <div>
                <span className="grey font-600-24 ">Treatments</span>
              </div>
              <i
                className="fa fa-xmark align-self-center pointer"
                onClick={() => {
                  setModal("treatment");
                }}
                style={{
                  fontSize: "30px",
                }}
              ></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex w-100">
              <div className="col-6 pe-2">
                <Form.Group>
                  <Form.Label className="black-label-text">
                    Treatment
                  </Form.Label>
                  <SelectComponent
                    searchEnabled
                    singleSelect
                    // isNullEnabled
                    // nullNotHidden
                    selectedValue={extraStates?.service_id}
                    options={options.treatments}
                    placeholder="Write..."
                    onChange={(e) => {
                      const isExists = sessionInvoices.find(
                        (o) => o.id == e.value
                      );
                      if (isExists) {
                        toastify(
                          "Treatment already exists in invoices",
                          "error"
                        );
                        return;
                      }
                      onExtraFormChange("service_id", e.value);
                      if (e.value) {
                        const unit_price = options?.treatments?.find(
                          (o) => o.id == e.value
                        )?.price;

                        onExtraFormChange("service_unit_price", unit_price);
                      }
                    }}
                    name={"service_id"}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black-label-text">Quantity</Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      type="number"
                      className="hideArrows"
                      name={"quantity"}
                      placeholder="Write..."
                      onChange={(e) => {
                        onExtraFormChange("service_quantity", e.target.value);
                      }}
                      value={extraStates.service_quantity}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black-label-text required">
                    Discount Type
                  </Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={extraStates?.service_discountType}
                    options={options.discountType}
                    onChange={(e) =>
                      onExtraFormChange("service_discountType", e.value)
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black-label-text">
                    Discount Value
                  </Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      type="number"
                      className="hideArrows"
                      name={"service_discount"}
                      placeholder="Write..."
                      onChange={(e) => {
                        onExtraFormChange("service_discount", e.target.value);
                      }}
                      value={extraStates.service_discount}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="black-label-text">Remark</Form.Label>
                  <InputGroup className="mb-3 ">
                    <Form.Control
                      name="service_remark"
                      as="textarea"
                      rows={2}
                      placeholder="Write..."
                      onChange={(e) => {
                        onExtraFormChange("service_remark", e.target.value);
                      }}
                      value={extraStates.service_remark}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-6 ps-2">
                {extraStates.service_id && (
                  <>
                    <div className="w-100 d-flex justify-content-between grey-div mb-2">
                      <div className="font-500-20">Unit Price </div>
                      <div className="font-400-20">
                        <>
                          {"$"}
                          {
                            options?.treatments?.find(
                              (o) => o.id == extraStates?.service_id
                            )?.price
                          }
                        </>
                      </div>
                    </div>
                  </>
                )}
                {extraStates.service_id &&
                  extraStates.service_quantity &&
                  !isNaN(extraStates.service_price) && (
                    <>
                      <div className="w-100 d-flex justify-content-between grey-div">
                        <div className="font-500-20">Total Price </div>
                        <div className="font-400-20">
                          {extraStates?.service_id && (
                            <>
                              {"$"}
                              {extraStates.service_price}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
            <Button
              className="addItem ms-auto mt-3"
              style={{ borderRadius: "25px", width: "150px" }}
              onClick={() => {
                if (
                  !extraStates.service_id ||
                  // !extraStates.service_price ||
                  !extraStates.service_quantity
                ) {
                  setModal("treatment");

                  return;
                }

                const tempArr = [...sessionInvoices];
                const treatmentDetail = options.treatments?.find(
                  (o) => o.id == extraStates.service_id
                );
                tempArr.push({
                  id: extraStates.service_id,
                  name: treatmentDetail?.name,
                  price: extraStates.service_unit_price,
                  qty: extraStates.service_quantity,
                  remark: extraStates.service_remark,
                  discount: extraStates.service_discount,
                  discountType: extraStates.service_discountType,
                  total_price: calcTotalWithDisc(
                    calcTotalWithDisc(
                      extraStates.service_price,
                      treatmentDetail?.promoDiscountValue,
                      treatmentDetail?.promoDiscountType
                    ),
                    extraStates.service_discount,
                    extraStates.service_discountType
                  ),
                  promoDiscountType: treatmentDetail?.promoDiscountType,
                  promoDiscountValue: treatmentDetail?.promoDiscountValue,
                  promoName: treatmentDetail?.promoName,
                  type: "SERVICE",
                  isExtra: true,
                });
                setSessionInvoices([...tempArr]);
                setRecalculateSub(!recalculateSub);

                setExtraStates((val) => {
                  return {
                    ...val,
                    service_remark: "",
                    service_discount: 0,
                    service_discountType: "Amount",
                    service_id: null,
                    service_quantity: null,
                    service_unit_price: null,
                    service_price: null,
                  };
                });
                setModal("treatment");
              }}
            >
              Save
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          show={showModal.edit}
          onHide={() => {
            setModal("edit");
          }}
        >
          <Modal.Header>
            <div className="d-flex justify-content-between w-100">
              <div>
                <span className="grey font-600-24 ">Edit</span>
              </div>
              <i
                className="fa fa-xmark align-self-center pointer"
                onClick={() => {
                  setModal("edit");
                }}
                style={{
                  fontSize: "30px",
                }}
              ></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mt-3">
              <Form.Label className="black-label-text">Quantity</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  name={"quantity"}
                  className="hideArrows"
                  placeholder="Write..."
                  onChange={(e) => {
                    setNewQty(e.target.value);
                  }}
                  value={newQty}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label className="black-label-text">
                Discount Type
              </Form.Label>
              <SelectComponent
                singleSelect
                searchEnabled
                defaultValue={newDiscType}
                selectedValue={newDiscType}
                options={options.discountType}
                onChange={(e) => {
                  setNewDiscType(e.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label className="black-label-text">Discount</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  name={"discount"}
                  className="hideArrows"
                  placeholder="Write..."
                  onChange={(e) => {
                    setNewDisc(e.target.value);
                  }}
                  value={newDisc}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label className="black-label-text">Remark</Form.Label>
              <InputGroup className="mb-3 ">
                <Form.Control
                  name="remark"
                  as="textarea"
                  rows={2}
                  placeholder="Write..."
                  onChange={(e) => {
                    setNewRemark(e.target.value);
                  }}
                  value={newRemark}
                />
              </InputGroup>
            </Form.Group>
            <Button
              className="addItem ms-auto"
              onClick={() => {
                let tempArr = [...sessionInvoices];
                tempArr = tempArr.map((o, i) => {
                  if (i == itemToEdit) {
                    if (o.type == "PACKAGE") {
                      let total_price = 0;
                      o.products?.map((product) => {
                        if (!product.ori_price) {
                          product.ori_price = (product.total_price / o.qty);
                        }
                        const newPrice = Number(product.ori_price) * newQty;
                        product.new_price = newPrice;
                        product.total_price = newPrice;
                        total_price += product.new_price;
                      });

                      o.treatments?.map((treatment) => {
                        if (!treatment.ori_price) {
                          treatment.ori_price = (treatment.total_price / o.qty);
                        }

                        const newPrice = Number(treatment.ori_price) * newQty;
                        treatment.new_price = newPrice;
                        treatment.total_price = newPrice;
                        total_price += treatment.new_price;
                      });

                      total_price = returnCalculatedPrice( 
                        total_price,
                        o.promoDiscountValue,
                        o.promoDiscountType
                      );

                      return {
                        ...o,
                        remark: newRemark,
                        qty: newQty,
                        discount: newDisc,
                        discountType: newDiscType,
                        total_price: total_price,
                      };
                    } else {
                      return {
                        ...o,
                        remark: newRemark,
                        qty: newQty,
                        discount: newDisc,
                        discountType: newDiscType,
                        total_price: returnCalculatedPrice(
                          itemToEditPrice,
                          o.promoDiscountValue,
                          o.promoDiscountType
                        ),
                      };
                    }
                  }
                  return o;
                });
                setSessionInvoices(tempArr);
                setRecalculateSub(!recalculateSub);
                setModal("edit");
              }}
            >
              Save
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          show={showModal.other}
          onHide={() => {
            setItemToEdit();
            onExtraFormChange("others_description", "");
            onExtraFormChange("others_price", 0);
            setModal("other");
          }}
        >
          <Modal.Header>
            <div className="d-flex justify-content-between w-100">
              <div>
                <span className="grey font-600-24 ">Other</span>
              </div>
              <i
                className="fa fa-xmark align-self-center pointer"
                onClick={() => {
                  setModal("other");
                }}
                style={{
                  fontSize: "30px",
                }}
              ></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mt-3">
              <Form.Label className="black-label-text">Description</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Write..."
                  onChange={(e) => {
                    setExtraStates({
                      ...extraStates,
                      others_description: e.target.value,
                    });
                  }}
                  value={extraStates.others_description}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label className="black-label-text">Price</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  // name={"remarks"}
                  type="number"
                  className="hideArrows"
                  placeholder="Write..."
                  onChange={(e) => {
                    setExtraStates({
                      ...extraStates,
                      others_price: e.target.value,
                    });
                  }}
                  value={extraStates?.others_price}
                />
              </InputGroup>
            </Form.Group>
            <Button
              className="addItem ms-auto"
              onClick={() => {
                if (!extraStates.others_description) {
                  toastify("Description is missing", "error");
                  return;
                }
                if (isNaN(extraStates.others_price)) {
                  toastify("Price is missing", "error");
                  return;
                }
                if (!isNaN(itemToEdit)) {
                  let tempArr = sessionInvoices.map((o, i) => {
                    if (i == itemToEdit) {
                      return {
                        ...o,
                        description: extraStates.others_description,
                        total_price: extraStates.others_price,
                      };
                    } else {
                      return o;
                    }
                  });
                  setSessionInvoices(tempArr);
                } else {
                  let tempArr = [
                    ...sessionInvoices,
                    {
                      description: extraStates.others_description,
                      total_price: extraStates.others_price,
                      type: "OTHER",
                      isExtra: true,
                    },
                  ];
                  setSessionInvoices(tempArr);
                }

                setRecalculateSub(!recalculateSub);
                onExtraFormChange("others_description", "");
                onExtraFormChange("others_price", 0);
                setItemToEdit();
                setModal("other");
              }}
            >
              Save
            </Button>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={showModal.adjustment}
          onHide={() => {
            setItemToEdit();
            onExtraFormChange("adjustment_name", "");
            onExtraFormChange("adjustment_discountType", "Amount");
            onExtraFormChange("adjustment_discount", 0);
            setModal("adjustment");
          }}
        >
          <Modal.Header>
            <div className="d-flex justify-content-between w-100">
              <div>
                <span className="grey font-600-24 ">Adjustment</span>
              </div>
              <i
                className="fa fa-xmark align-self-center pointer"
                onClick={() => {
                  setModal("adjustment");
                }}
                style={{
                  fontSize: "30px",
                }}
              ></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mt-3">
              <Form.Label className="black-label-text required">
                Name
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Write..."
                  onChange={(e) => {
                    setExtraStates({
                      ...extraStates,
                      adjustment_name: e.target.value,
                    });
                  }}
                  value={extraStates.adjustment_name}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label className="black-label-text required">
                Discount Type
              </Form.Label>
              <SelectComponent
                singleSelect
                searchEnabled
                selectedValue={extraStates?.adjustment_discountType}
                options={options.discountType}
                onChange={(e) =>
                  onExtraFormChange("adjustment_discountType", e.value)
                }
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label className="black-label-text">
                Discount Value
              </Form.Label>
              <InputGroup className="mb-3 ">
                <Form.Control
                  type="number"
                  className="hideArrows"
                  name={"adjustment_discount"}
                  placeholder="Write..."
                  onChange={(e) => {
                    onExtraFormChange("adjustment_discount", e.target.value);
                  }}
                  value={extraStates.adjustment_discount}
                />
              </InputGroup>
            </Form.Group>
            <Button
              className="addItem ms-auto"
              onClick={() => {
                if (!extraStates.adjustment_name) {
                  toastify("Name is missing", "error");
                  return;
                }
                if (!extraStates.adjustment_discountType) {
                  toastify("Discount Type is missing", "error");
                  return;
                }
                if (!isNaN(itemToEdit)) {
                  let tempArr = paymentAdjustments.map((o, i) => {
                    if (i == itemToEdit) {
                      return {
                        ...o,
                        name: extraStates.adjustment_name,
                        discountType: extraStates.adjustment_discountType,
                        discount: extraStates.adjustment_discount,
                      };
                    } else {
                      return o;
                    }
                  });
                  setPaymentAdjustments(tempArr);
                } else {
                  let tempArr = [
                    ...paymentAdjustments,
                    {
                      name: extraStates.adjustment_name,
                      discountType: extraStates.adjustment_discountType,
                      discount: extraStates.adjustment_discount,
                    },
                  ];
                  setPaymentAdjustments(tempArr);
                }

                setRecalculateSub(!recalculateSub);
                onExtraFormChange("adjustment_name", "");
                onExtraFormChange("adjustment_discountType", "Amount");
                onExtraFormChange("adjustment_discount", 0);
                setItemToEdit();
                setModal("adjustment");
              }}
            >
              Save
            </Button>
          </Modal.Body>
        </Modal>

        {invoicePayment && (
          <BlobProvider
            document={
              <InvoicePDF billing={invoicePayment} setting={companySetting} />
            }
          >
            {({ blob }) => {
              if (blob !== null) {
                setFileInvoice(blob);
              }
              return "";
            }}
          </BlobProvider>
        )}

        {modalLabelPDF && (
          <Modal
            centered
            show={modalLabelPDF}
            onHide={() => {
              setmodalLabelPDF(false);
            }}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Internal Drug
              </Modal.Title>
            </Modal.Header>

            <div className="pdf-btn">
              <PDFViewer style={{ width: "100%", height: "500px" }}>
                <InternalPdf setting={companySetting} details={selectedLabel} />
                {/* <ExternalPDF
                  products={products_data}
                  templates={externalTemplates}
                /> */}
              </PDFViewer>
            </div>
          </Modal>
        )}

        {modalExternal && (
          <Modal
            centered
            show={modalExternal}
            onHide={() => {
              setModalExternal(false);
            }}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                External Drug
              </Modal.Title>
            </Modal.Header>

            <div className="pdf-btn">
              <PDFViewer style={{ width: "100%", height: "500px" }}>
                <ExternalPDF
                  products={products_data}
                  templates={externalTemplates}
                />
              </PDFViewer>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
