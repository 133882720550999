import api, { authorizedRequests } from "../axios/axios-config";

export const getSessions = async () => {
  try {
    let response = await api.get("session/listOfSession");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const uploadSessionFiles = async (data) => {
  try {
    const response = await api.post(`session/updateSessionFolder`, data);
    return { result: response };
  } catch (error) {
    return { error: error?.response?.data };
  }
};

export const deleteSessionFiles = async (data) => {
  try {
    const response = await api.post(`session/deleteSessionFiles`, data);
    return { result: response };
  } catch (error) {
    return { error: error?.response?.data };
  }
};

export const updateSessionPhoto = async (data) => {
  try {
    const response = await api.post(`session/updateSessionPhoto`, data);
    return { result: response };
  } catch (error) {
    return { error: error?.response?.data };
  }
};

export const updateSession = async (data) => {
  const response = await authorizedRequests({
    url: "session/updateSession",
    data,
    method: "post",
  });
  return response;
};

export const getSessionById = async (id) => {
  const response = await authorizedRequests({
    url: "session/getSessionById",
    data: {
      dataId: id,
    },
    method: "post",
  });
  return response;
};

export const getSessionByPatientId = async (id) => {
  const response = await authorizedRequests({
    url: "session/getSessionByPatientId",
    data: {
      patientId: id,
    },
    method: "post",
  });
  return response;
};

export const getSessionByAppointment = async (id) => {
  const response = await authorizedRequests({
    url: "session/getSessionByAppointment",
    data: {
      dataId: id,
    },
    method: "post",
  });
  console.log(response);
  return response;
};

export const createPatientChartTemplate = async (data) => {
  try {
    const response = await api.post(`session/createPatientChartTemplate`, data);
    return { result: response };
  } catch (error) {
    return { error: error?.response?.data };
  }
};

export const readPatientChartTemplate = async () => {
  try {
    let response = await api.get("session/readPatientChartTemplate");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updatePatientChartTemplate = async (data) => {
  try {
    const response = await api.post(`session/updatePatientChartTemplate`, data);
    return { result: response };
  } catch (error) {
    return { error: error?.response?.data };
  }
};
export const deletePatientChartTemplate = async (data) => {
  try {
    let response = await api.post("session/deletePatientChartTemplate", data);
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
