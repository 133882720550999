import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { thousandAndDecimalFormat } from "../../../utils/common";
import {
  getShortMonth,
  formatDate,
  generateRandomStr,
  updatePercentageLabel,
} from "../../../helperFunctions/utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34556f",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const namePeriod = {
  revenue: "Revenue",
  profit: "Profit",
  cost: "Cost",
  "product cost": "Product Cost",
  percentage: "Percentage",
  "percentage revenue": "Percentage of Revenue",
  "percentage profit": "Percentage of Profit",
  average: "Average",
  paitent: "Patient",
  new_patient: "New Patient",
  new_paitent: "New Patient",
  referals: "Referral",
  media_Kol: "Media / KOL",
  recurring_paitents: "Recurring Patients",
  existing_paitents: "Existing Patients",
};

export const getNamePeriod = (name) => {
  return namePeriod[name] ? namePeriod[name] : name;
};

const DasboardTable = ({
  name,
  filterData,
  rangeMonth,
  values,
  firstField,
  secondField,
  setSelectedDetailData,
  setShow,
  export_pdf,
  showTotal,
  showPercentage,
  setDetailDataResp,
}) => {
  const firstRangeRef = useRef(null);

  const getMonthValue = (monthValues, year, month) => {
    const monthValue = monthValues?.find(
      (m) => m.year === year && m.month === month
    );
    if (!monthValue) return 0;

    if (!monthValue.count) return 0;

    return monthValue?.count == 0 ? 0 : monthValue?.count?.length ?? 0;
  };

  const getMonthDetail = (monthValues, year, month) => {
    const monthValue = monthValues?.find(
      (m) => m.year === year && m.month === month
    );
    if (!monthValue) return 0;

    if (!monthValue.count) return 0;

    return monthValue?.count;
  };

  function isLessThanThirtyDays(date1, date2) {
    const oneDayInMs = 24 * 60 * 60 * 1000;
    const differenceInMs = date2.getTime() - date1.getTime();
    const differenceInDays = differenceInMs / oneDayInMs;
    return differenceInDays < 30;
  }

  const getRowDetail = (period, monthYearString, index) => {
    const split = monthYearString.split("/");
    const checkMonthYear = () => {
      const first = isLessThanThirtyDays(
        new Date(filterData.r1_start_date),
        new Date(filterData.r1_end_date)
      );
      const second = isLessThanThirtyDays(
        new Date(filterData.r2_start_date),
        new Date(filterData.r2_end_date)
      );
      if (first && second && index % 2 !== 0) {
        return {
          r1_start_date: filterData.r2_start_date,
          r1_end_date: filterData.r2_end_date,
          r2_start_date: filterData.r2_start_date,
          r2_end_date: filterData.r2_end_date,
        };
      } else if (first && second && index % 2 === 0) {
        return {
          r1_start_date: filterData.r1_start_date,
          r1_end_date: filterData.r1_end_date,
          r2_start_date: filterData.r1_start_date,
          r2_end_date: filterData.r1_end_date,
        };
      }
      return {
        r1_start_date: `${split[1]}-${split[0].padStart(2, "0")}-01`,
        r1_end_date: `${split[1]}-${split[0].padStart(2, "0")}-31`,
        r2_start_date: `${split[1]}-${split[0].padStart(2, "0")}-01`,
        r2_end_date: `${split[1]}-${split[0].padStart(2, "0")}-31`,
      };
    };
    const monthYear = checkMonthYear();
    if (period === "new_paitent") {
      setSelectedDetailData({
        ...monthYear,
        typeName: "New Patient",
        type: "N_P",
      });
    } else if (period === "referals") {
      setSelectedDetailData({
        ...monthYear,
        typeName: "Referral",
        type: "REF",
      });
    } else if (period === "media_Kol") {
      setSelectedDetailData({
        ...monthYear,
        typeName: "Media / KOL",
        type: "KOL",
      });
    } else if (period === "recurring_paitents") {
      setSelectedDetailData({
        ...monthYear,
        typeName: "Recurring Patients",
        type: "REC_P",
      });
    } else if (period === "existing_paitents") {
      setSelectedDetailData({
        ...monthYear,
        typeName: "Existing Patients",
        type: "EXIS_P",
      });
    } else if (period === "20s") {
      setSelectedDetailData({
        ...monthYear,
        typeName: "20S",
        type: 20,
        tableName: name,
      });
    } else if (period === "30s") {
      setSelectedDetailData({
        ...monthYear,
        typeName: "30S",
        type: 30,
        tableName: name,
      });
    } else if (period === "40s") {
      setSelectedDetailData({
        ...monthYear,
        typeName: "40S",
        type: 40,
        tableName: name,
      });
    } else if (period === "50s") {
      setSelectedDetailData({
        ...monthYear,
        typeName: "50S",
        type: 50,
        tableName: name,
      });
    } else if (period === "60s") {
      setSelectedDetailData({
        ...monthYear,
        typeName: "60S",
        type: 60,
        tableName: name,
      });
    } else if (period === "70s") {
      setSelectedDetailData({
        ...monthYear,
        typeName: "70S",
        type: 70,
        tableName: name,
      });
    } else {
      setSelectedDetailData({
        ...monthYear,
        typeName: period,
        type: period,
        tableName: name,
      });
    }
    setShow(true);
  };

  // Use the function to update the values before merging
  const updatedValues = updatePercentageLabel("percentage", values);

  const renderTable = (range, index, start, end, field) => {
    return (
      <Table key={generateRandomStr(10)} sx={{ marginTop: index > 0 ? 4 : 4 }}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell
              align="center"
              colSpan={range.length + 1}
              sx={{ fontSize: 18 }}
            >
              {formatDate(start)} - {formatDate(end)}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell colSpan={1.5}>Period</StyledTableCell>
            {range?.map((monthValue, mIdx) => (
              <StyledTableCell key={generateRandomStr(10)} align="center">
                {getShortMonth(monthValue.month)} / {monthValue.year}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {values?.map((item) => (
            <StyledTableRow
              key={generateRandomStr(10)}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {getNamePeriod(item.period)}
              </StyledTableCell>
              {range?.map((monthValue, mIdx) => (
                <StyledTableCell
                  key={generateRandomStr(10)}
                  component="th"
                  scope="row"
                  align="center"
                >
                  {thousandAndDecimalFormat(
                    getMonthValue(
                      item[field],
                      monthValue.year,
                      monthValue.month + 1
                    ),
                    true,
                    2
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    );
  };
  const numTables = Math.ceil(rangeMonth.r1.length / 12);
  const numTables2 = Math.ceil(rangeMonth.r2.length / 12);

  const mergedData = updatedValues?.map((periodData) => {
    const mergedYear = [];
    const r1Year = periodData.r1year || [];
    const r2Year = periodData.r2year || [];

    // Merge r1year and r2year arrays
    for (let i = 0; i < Math.max(r1Year.length, r2Year.length); i++) {
      const r1Item = r1Year[i];
      const r2Item = r2Year[i];

      if (
        r1Item &&
        r2Item &&
        r1Item.year === r2Item.year &&
        r1Item.month === r2Item.month
      ) {
        // If both arrays have an object with the same year and month
        // Prioritize the object with non-zero count
        if (r1Item.count !== 0 || r2Item.count !== 0) {
          mergedYear.push(r1Item.count !== 0 ? r1Item : r2Item);
          mergedYear.push(r1Item.count !== 0 ? r2Item : r1Item);
        } else {
          // If both have count 0, add both objects
          mergedYear.push(r1Item, r2Item);
        }
      } else {
        // If only one array has an item or they have different years or months
        if (r1Item) mergedYear.push(r1Item);
        if (r2Item) mergedYear.push(r2Item);
      }
    }

    return { period: periodData.period, year: mergedYear };
  });

  const mergedArray = [];

  // Assuming both arrays have the same length
  for (let i = 0; i < rangeMonth?.r1?.length; i++) {
    const r1 = rangeMonth?.r1[i];
    const r2 = rangeMonth?.r2[i] || 0;
    if (showTotal) {
      r1.total = thousandAndDecimalFormat(
        mergedData?.reduce(
          (acc, value) =>
            acc + getMonthValue(value[firstField], r1?.year, r1?.month + 1),
          0
        ),
        true,
        2
      );
      r2.total = thousandAndDecimalFormat(
        mergedData?.reduce(
          (acc, value) =>
            acc + getMonthValue(value[firstField], r2?.year, r2?.month + 1),
          0
        ),
        true,
        2
      );
    }
    mergedArray.push(r1, r2);
  }

  const syncScroll = (className, scrollLeft) => {
    document.querySelectorAll(`.${className}`).forEach((element) => {
      element.scrollLeft = scrollLeft;
    });
  };

  useEffect(() => {
    const syncScrollClass = (e) => {
      const scrollLeft = e.target.scrollLeft;
      syncScroll("sync-scroll", scrollLeft);
    };

    const elements = document.querySelectorAll(".sync-scroll");
    elements.forEach((element) => {
      element.addEventListener("scroll", syncScrollClass);
    });

    return () => {
      elements.forEach((element) => {
        element.removeEventListener("scroll", syncScrollClass);
      });
    };
  }, [firstRangeRef?.current]);

  let currentColumnHeader = 0;
  let currentColumnTotal = 0;

  return export_pdf ? (
    <>
      {rangeMonth.r1.length <= 12 ? (
        <Grid container spacing={2} sx={{ marginTop: 3 }}>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell
                  align="center"
                  colSpan={rangeMonth.r1.length + 1}
                  sx={{ fontSize: 18 }}
                >
                  {formatDate(filterData.r1_start_date)} -{" "}
                  {formatDate(filterData.r1_end_date)}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell colSpan={1.5}>Period</StyledTableCell>
                {rangeMonth.r1?.map((monthValue, mIdx) => (
                  <StyledTableCell align="center">
                    {getShortMonth(monthValue.month)} / {monthValue.year}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {values?.map((item) => (
                <StyledTableRow
                  key={generateRandomStr(10)}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {getNamePeriod(item.period)}
                  </StyledTableCell>
                  {rangeMonth.r1?.map((monthValue, mIdx) => (
                    <StyledTableCell component="th" scope="row" align="center">
                      {thousandAndDecimalFormat(
                        getMonthValue(
                          item[firstField],
                          monthValue.year,
                          monthValue.month + 1
                        ),
                        true,
                        2
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      ) : (
        [...Array(numTables)].map((_, index) => {
          const startIdx = index * 12;
          const endIdx = startIdx + 12;
          const currentRange = rangeMonth.r1.slice(startIdx, endIdx);
          return renderTable(
            currentRange,
            index,
            filterData.r1_start_date,
            filterData.r1_end_date,
            firstField
          );
        })
      )}

      {rangeMonth.r2.length <= 12 ? (
        <Grid container spacing={2} sx={{ marginTop: 3 }}>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell
                  align="center"
                  colSpan={rangeMonth.r2.length + 1}
                  sx={{ fontSize: 18 }}
                >
                  {formatDate(filterData.r2_start_date)} -{" "}
                  {formatDate(filterData.r2_end_date)}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell colSpan={1.5}>Period</StyledTableCell>
                {rangeMonth.r2?.map((monthValue, mIdx) => (
                  <StyledTableCell align="center">
                    {getShortMonth(monthValue.month)} / {monthValue.year}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {values?.map((item) => (
                <StyledTableRow
                  key={generateRandomStr(10)}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {getNamePeriod(item.period)}
                  </StyledTableCell>
                  {rangeMonth.r2?.map((monthValue, mIdx) => (
                    <StyledTableCell component="th" scope="row" align="center">
                      {thousandAndDecimalFormat(
                        getMonthValue(
                          item[secondField],
                          monthValue.year,
                          monthValue.month + 1
                        ),
                        true,
                        2
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      ) : (
        [...Array(numTables2)].map((_, index) => {
          const startIdx = index * 12;
          const endIdx = startIdx + 12;
          const currentRange = rangeMonth.r2.slice(startIdx, endIdx);

          return renderTable(
            currentRange,
            index,
            filterData.r2_start_date,
            filterData.r2_end_date,
            secondField
          );
        })
      )}
    </>
  ) : (
    <div className="table-block">
      <div className="table-header">
        <div className="table-col">
          <h3 className="col-title">Period</h3>
        </div>
        <div className="table-col">
          <h3 className="col-title">
            {formatDate(filterData.r1_start_date)} -{" "}
            {formatDate(filterData.r1_end_date)}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {formatDate(filterData.r2_start_date)} -{" "}
            {formatDate(filterData.r2_end_date)}
          </h3>
          <div id={`firstRange_${name}0`} className="sub-col sync-scroll">
            {mergedArray?.map((monthValue, mIdx) => {
              if (currentColumnHeader % 2 === 0) currentColumnHeader = 1;
              else currentColumnHeader++;
              return (
                <>
                  <h3 key={generateRandomStr(10)} className="col-title">
                    {getShortMonth(monthValue?.month)} / {monthValue?.year}
                  </h3>
                  {showPercentage && currentColumnHeader === 2 && (
                    <h3 key={generateRandomStr(10)} className="col-title">
                      Percentage
                    </h3>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
      {mergedData?.map((item, index) => {
        let currentColumn = 0;
        let currentColumnValue = undefined;
        return (
          <div key={generateRandomStr(10)} className="table-rows">
            <div className="table-col">
              <h3 className="col-title">{getNamePeriod(item.period)}</h3>
            </div>
            <div className="table-col">
              <div
                id={`firstRange_${name}${index + 1}`}
                className="sub-col sync-scroll"
                ref={mergedData?.length - 1 === index ? firstRangeRef : null}
              >
                {mergedArray?.map((monthValue, mIdx) => {
                  if (currentColumn % 2 === 0) {
                    currentColumn = 1;
                    currentColumnValue = getMonthValue(
                      item[firstField],
                      monthValue?.year,
                      monthValue?.month + 1
                    );
                  } else {
                    currentColumn++;
                  }
                  const value = getMonthValue(
                    item[firstField],
                    monthValue?.year,
                    monthValue?.month + 1
                  );

                  console.log(value, monthValue?.month + 1, monthValue?.year);

                  let renderValue = () => {
                    if (value !== 0 && currentColumnValue !== 0) {
                      return `${(
                        ((value - currentColumnValue) / currentColumnValue) *
                        100
                      ).toFixed(2)}%`;
                    } else if (value !== 0 && currentColumnValue === 0) {
                      return "100%";
                    } else if (value === 0 && currentColumnValue !== 0) {
                      return "-100%";
                    } else {
                      return "-";
                    }
                  };
                  return (
                    <>
                      <h3
                        key={generateRandomStr(10)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                          margin: 0,
                          flex: 1,
                          height: "100%",
                          minWidth: "125px",
                          borderRight: "1px solid lightgray",
                        }}
                        onClick={(e) => {
                          if (
                            item.period !== "percentage" &&
                            item.period !== "average" &&
                            item.period !== "Customer Acquisition"
                          ) {
                            setDetailDataResp(
                              getMonthDetail(
                                item[firstField],
                                monthValue?.year,
                                monthValue?.month + 1
                              )
                            );
                            setShow(true);
                            // getRowDetail(
                            //   item.period,
                            //   `${getShortMonth(monthValue?.month)}/${
                            //     monthValue?.year
                            //   }`,
                            //   mIdx
                            // );
                          }
                        }}
                      >
                        {thousandAndDecimalFormat(value, true, 2)}
                      </h3>
                      {showPercentage && currentColumn === 2 && (
                        <h3
                          key={generateRandomStr(10)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "normal",
                            margin: 0,
                            flex: 1,
                            height: "100%",
                            minWidth: "125px",
                            borderRight:
                              mIdx % 2 !== 0
                                ? "2px solid black"
                                : "1px solid lightgray",
                          }}
                        >
                          {renderValue()}
                        </h3>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      {showTotal && mergedData?.length > 0 && (
        <div className="table-rows">
          <div className="table-col">
            <h3 className="col-title" style={{ fontWeight: "bold" }}>
              Total
            </h3>
          </div>
          <div className="table-col">
            <div id={`totalRange`} className="sub-col sync-scroll" ref={null}>
              {mergedArray?.map((monthValue, mIdx) => {
                if (currentColumnTotal % 2 === 0) {
                  currentColumnTotal = 1;
                } else {
                  currentColumnTotal++;
                }
                return (
                  <>
                    <h3
                      key={generateRandomStr(10)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "15px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        margin: 0,
                        flex: 1,
                        height: "100%",
                        minWidth: "125px",
                        borderRight: "1px solid lightgray",
                      }}
                    >
                      {monthValue.total}
                    </h3>
                    {currentColumnTotal === 2 && (
                      <h3
                        key={generateRandomStr(10)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                          margin: 0,
                          flex: 1,
                          height: "100%",
                          minWidth: "125px",
                          borderRight:
                            mIdx % 2 !== 0
                              ? "2px solid black"
                              : "1px solid lightgray",
                        }}
                      >
                        -
                      </h3>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DasboardTable;
